import {Helmet} from 'react-helmet-async';

const SITE_TITLE = 'Patch Program';

function SEO({
    title = null,
    meta = [],
}) {
    return (
        <Helmet
            title={title}
            titleTemplate={SITE_TITLE ? `%s | ${SITE_TITLE}` : null}
            meta={meta}
        />
    )
}

export default SEO
