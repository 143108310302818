import {CutInfo, CutInfoBox, CutInfoHeader, IconHover} from './Sidebar.styles';
import Modal from 'components/Modal/Modal';
import useModal from 'components/Modal/useModal';
import EditCutForm from 'components/Form/EditCutForm';
import Row from 'components/Grid/Row';
import { useRef } from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {regular, solid} from '@fortawesome/fontawesome-svg-core/import.macro';

function CutInfoContainer({ cut, refreshCuts, setLoading }) {
    const {isShowing, toggleModal} = useModal();

    //Colors for headers
    let headerStyle = {background: '#2da322'};
    let orderDate = cut.project.orderDate;

    function isMoreThanNDaysAgo(orderDate, days, lessThan = false) {
        const transformedDate = new Date(orderDate);
        //                   days  hours min  sec  ms
        const thirtyDaysInMs = days * 24 * 60 * 60 * 1000;
        const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

        if (lessThan) {
            return timestampThirtyDaysAgo < transformedDate;
        }

        return timestampThirtyDaysAgo > transformedDate;
    }

    if (isMoreThanNDaysAgo(orderDate, 7) && isMoreThanNDaysAgo(orderDate, 30, true)) {
        headerStyle = {background: '#ffbc03'};
    } else if (isMoreThanNDaysAgo(orderDate, 30)) {
        headerStyle = {background: '#ff4a26'};
    }

    let latestStep = '';

    if (cut.steps.length === 0){
        latestStep = "Digout";
    } else if (cut.steps.length === 1) {
        latestStep = "Base";
    } else if (cut.steps.length === 2) {
        latestStep = "Surface";
    } else {
        latestStep = "Completed"
    }

    if (cut.product.name === "Concrete" && cut.steps.length > 0){
        latestStep = "Completed";
    } else if (cut.product.name === "Concrete - Curb" && cut.steps.length > 0){
        latestStep = "Completed";
    }

    const formattedDate = new Date(orderDate).toLocaleDateString('en-us', {  year:"numeric", month:"long", day:"numeric"});

    // Scroll to success message when form submitted
    const modalRef = useRef(null);

    const scrollTop = () => {
        modalRef.current.scrollIntoView();
    }

    return (
        <CutInfoBox>
            <CutInfoHeader style={headerStyle}>
                {cut.address} {cut.street} - {cut.product.name}
                <IconHover>
                    <FontAwesomeIcon icon={regular('pen-to-square')} onClick={toggleModal} />
                </IconHover>
                <Modal isShowing={isShowing} hide={toggleModal} title="Edit Entry" modalRef={modalRef}>
                    <EditCutForm cut={cut} formattedDate={formattedDate} latestStep={latestStep} refreshCuts={refreshCuts} setLoading={setLoading} toggleModal={toggleModal} scrollTop={scrollTop}/>
                </Modal>
            </CutInfoHeader>
            <CutInfo>
                <Row>
                    <FontAwesomeIcon icon={regular('calendar')} style={{marginRight:"7px"}} />{formattedDate}
                    <FontAwesomeIcon icon={solid('user')} style={{marginRight:"7px", marginLeft:"15px"}} />{cut?.project?.client?.name}
                </Row>
            </CutInfo>
            {latestStep === "Digout" && (
                <CutInfo>
                    <FontAwesomeIcon icon={solid('person-digging')} style={{marginRight:"7px"}} />{latestStep}
                </CutInfo>
            )}
            {latestStep === "Base" && (
                <CutInfo>
                    <FontAwesomeIcon icon={solid('mound')} style={{marginRight:"7px"}} />{latestStep}
                </CutInfo>
            )}
            {latestStep === "Surface" && (
                <CutInfo>
                    <FontAwesomeIcon icon={solid('truck')} style={{marginRight:"7px"}} />{latestStep}
                </CutInfo>
            )}
            {latestStep === "Completed" && (
                <CutInfo>
                    <FontAwesomeIcon icon={regular('square-check')} style={{marginRight:"7px"}} />{latestStep}
                </CutInfo>
            )}
            {cut.product.name !== 'Additional Work' && cut.originalLength && cut.originalWidth && cut.originalDepth && (
                <CutInfo>
                    <h6>Original:</h6>
                    {cut.originalLength} in. x {cut.originalWidth} in. x {cut.originalDepth} in.
                </CutInfo>
            )}
            {cut.product.name !== 'Additional Work' && cut.actualLength && cut.actualWidth && cut.actualDepth && (
                <CutInfo>
                    <h6>Actual:</h6>
                    {cut.actualLength} in. x {cut.actualWidth} in. x {cut.actualDepth} in.
                </CutInfo>
            )}
            {cut.comments && (
                <CutInfo>
                    <h6>Comments:</h6>
                    {cut.comments}
                </CutInfo>
            )}
        </CutInfoBox>
    );
}

export default CutInfoContainer
