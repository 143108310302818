import SEO from 'components/SEO/SEO';
// import { Link } from "react-router-dom";
import Container from "components/Grid/Container";
import ProjectForm from "components/Form/ProjectForm";
import {useParams} from "react-router-dom";

function AddProject() {
    let { id } = useParams();

    return (
        <>
        <SEO title="Add Project" />

        <Container vpadding={30}>
        <h2>Add New Project</h2>
            <ProjectForm id={id} />
        </Container>
        </>
    )
}

export default AddProject;
