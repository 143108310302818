import {isPlainObject} from "lodash";
import {useEffect, useState} from "react";
import AbstractInputField from "./AbstractInputField";
import {StyledSelect} from "./Form.styles";
import {nanoid} from 'nanoid'

const SelectField = ({
    placeholder,
    choices = [],
    value,
    ...otherProps
}) => {
    const [convertedChoices, setConvertedChoices] = useState([]);
    const [convertedValue, setConvertedValue] = useState('');

    useEffect(() => {
        if (!choices || !choices?.length) {
            return;
        }

        const newConvertedChoices = choices.map( choice => {
            if (typeof choice === 'string') {
                return {
                    label: choice,
                    value: choice,
                };
            }

            if ( isPlainObject(choice) ) {
                if (choice?.label && choice?.value) {

                    return {
                        label: choice.label,
                        value: choice.value,
                    };
                }

                if (choice?.label && !choice?.value) {
                    return {
                        label: choice.label,
                        value: choice.label,
                    };
                }

                if (!choice?.label && choice?.value) {
                    return {
                        label: choice.value,
                        value: choice.value,
                    };
                }
            }
        } );

        setConvertedChoices(newConvertedChoices);
    }, [choices])

    useEffect(() => {
        setConvertedValue(value);

        if (typeof value === 'object') {
            setConvertedValue(JSON.stringify(value));
        }
    }, [value]);

    return (
        <AbstractInputField
            // placeholder={placeholder}
            choices={convertedChoices}
            InputComponent={StyledSelect}
            value={convertedValue}
            inputComponentProps={{
                children: [
                    ...(placeholder ? [<option key={nanoid(5)} hidden>{placeholder}</option>] : []),
                    ...convertedChoices.map( choice => (
                        <option value={typeof choice.value === 'object' ? JSON.stringify(choice.value) : choice.value} key={nanoid(5)}>{choice.label}</option>
                    ) )
                ]
            }}
            {...otherProps}
        />
    );
}

export default SelectField;
