import React, { useState } from 'react';
import { SubmitButton } from 'components/Button/Button.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import { useDavidApi } from 'utility/DavidApi/DavidApi';
import TextField from './FormComponents/TextField';
import Form from './FormComponents/Form';
import LoadingOverlay from 'components/Layout/MapView/LoadingOverlay/LoadingOverlay';

function EditClientForm({
    client,
    hideModal
}) {
    const api = useDavidApi();
    const [inputs, setInputs] = useState({
        name: client.name,
        address: client.address,
        address1: client.address1,
        city: client.city,
        state: client.state,
        zipcode: client.zipcode,
        phone: client.phone,
        fax: client.fax
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const submitHandler = async (values, id) => {
        setLoading(true);
        await api.patchClient(values, id);

        if(hideModal) {
            setTimeout(() => {
                hideModal();
                setLoading(false);
            }, 
                1500
            );
        }
    }

    return (
      <Form 
        successMessage='Client updated!' 
        id={client.id} 
        values={inputs} 
        setValues={setInputs} 
        asyncSubmitHandler={submitHandler}
        errors={errors}
        setErrors={setErrors}
    >
    {loading && (
        <LoadingOverlay>
            <h2>
                Client updated! Refreshing...
            </h2>
        </LoadingOverlay>
    )}
        <TextField 
            label="Client Name"
            name="name"
            required
        />
        <TextField
            label="Address"
            name="address"
        />
        <TextField
            label="Address Line 2"
            name="address1"
        />
        <Row gutter={15}>
            <Column width={1/3}>
                <TextField
                    label="City"
                    name="city"
                />
            </Column>
            <Column width={1/3}>
                <TextField
                    label="State"
                    name="state"
                />
            </Column>
            <Column width={1/3}>
                <TextField
                    label="Zipcode"
                    name="zipcode"
                />
            </Column>
        </Row>
        <Row gutter={15}>
            <Column width={1/2}>
                <TextField
                    label="Phone"
                    name="phone"
                    required
                />
            </Column>
            <Column width={1/2}>
                <TextField
                    label="Fax"
                    name="fax"
                />
            </Column>
        </Row>
        <SubmitButton>Submit</SubmitButton>
      </Form>
    )
  }

export default EditClientForm;
