import React, {createContext, useRef, useState} from 'react';
import axios from 'axios';
import set from 'lodash.set';
import { getChildrenDeep } from 'react-nanny';
import isEmpty from 'lodash.isempty';

import PageMessage from 'components/PageMessage/PageMessage';
import {StyledForm, StyledSuccessMessageWrapper} from './Form.styles';
import FormFieldError from './FormFieldError';

export const FormContext = createContext();

const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required.';

const Form = ({
    id,
    url = null,
    method = 'post',
    async = true,
    asyncSubmitHandler = null,
    successMessage = 'Thank you!',
    successCallback = null,
    values = {},
    setValues = null,
    errors = {},
    setErrors = null,
    children,
    ...otherProps
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const formRef = useRef(null);

    // useEffect(() => {
    //     console.log(values);
    // }, [values]);

    const inputChangeHandler = (name, val) => {
        // console.log(name);
        // console.log(val);
        setValues( (values) => set({...values}, name, val) );
    }

    const handleSubmit = async (e) => {
        const validationErrors = validateForm();

        if ( !isEmpty(validationErrors) ) {
            e.preventDefault();
            setSubmitting(false);
            return false;
        }

        if (method.toLowerCase() === 'get') {
            e.preventDefault();
            e.stopPropagation()
            setSubmitting(true);

            // const currentQueryString = new URLSearchParams(window.location.search).toString();
            // const newQueryString = new URLSearchParams( new FormData(formRef.current) ).toString();

            // if (currentQueryString === newQueryString) {
            //     // show "submitting" visual briefly
            //     setTimeout(() => {
            //         setSubmitting(false);
            //         setReadOnly(false);
            //     }, 500);

            //     return false;
            // }

            return false;
        }

        if (async) {
            e.preventDefault();
            e.stopPropagation()
        }

        // console.log(values);

        setSubmitting(true);

        if (async) {

            if (asyncSubmitHandler && typeof asyncSubmitHandler === 'function') {
                const response = await asyncSubmitHandler(values, id);
                setSubmitting(false);
                setSubmitted(true);

                if (successCallback && typeof successCallback === 'function') {
                    successCallback(response);
                }
                return false;
            }

            axios
            .post(url, values)
            .then( response => {
                console.log(response);
                setSubmitting(false);
                setSubmitted(true);

                if (successCallback && typeof successCallback === 'function') {
                    successCallback(response);
                }
            })
            .catch( error => {
                console.log(error);
                setSubmitting(false);
            });
        }
    }

    const validateForm = () => {
        let validationErrors = {};
        let requiredFields = getChildrenDeep(children, (child) => child?.props?.required && child?.props?.name);
        
        for (const requiredField of requiredFields) {
            const requiredFieldName = requiredField?.props?.name;
            const requiredFieldValue = values?.[requiredFieldName];

            if (requiredFieldValue) {
                continue;
            }

            validationErrors[requiredFieldName] = REQUIRED_FIELD_ERROR_MESSAGE;
        }

        setErrors(validationErrors);
        return validationErrors;
    }

    return (
        <FormContext.Provider value={{
            values:values, 
            setValues:setValues, 
            inputChangeHandler:inputChangeHandler, 
            errors:errors, 
            setErrors:setErrors,
        }}>
            <StyledForm
                ref={formRef}
                method={!async ? method : undefined}
                action={!async && url ? url : undefined}
                onSubmit={handleSubmit}
                {...otherProps}
            >
                {submitted && successMessage !== "" && (
                    <StyledSuccessMessageWrapper>
                        <PageMessage type="success">{successMessage}</PageMessage>
                    </StyledSuccessMessageWrapper>
                )}

                {children}

                {!isEmpty(errors) && (
                    <FormFieldError>
                        There are errors with one or more fields on this form, marked in red just like this message. Please correct them, then submit again.
                    </FormFieldError>
                )}
            </StyledForm>
        </FormContext.Provider>
    );
}

export default Form;
