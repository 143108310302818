import React, {useContext, useEffect, useState} from 'react';
import {SubmitButton} from 'components/Button/Button.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import FuzzySearchSelect from 'components/FuzzySearch/FuzzySearchSelect';
import TextField from './FormComponents/TextField';
import Form, {FormContext} from './FormComponents/Form';
import CutUpdatedForm from "./CutUpdatedForm";
import {useLocation, useNavigate} from "react-router-dom";
import DateField from "./FormComponents/DateField";
import ArrayField from "./FormComponents/ArrayField";
import AddClientForm from "./AddClientForm";
import LoadingOverlay from "../Layout/MapView/LoadingOverlay/LoadingOverlay";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {regular} from "@fortawesome/fontawesome-svg-core/import.macro";

function ProjectForm({id}) {
    const api = useDavidApi();
    const formContext = useContext(FormContext);
    const cutInitialState = {
        steps: [],
        product: '',
        street: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        comments: '',
        monoCurb: null,
        flowableFill: null,
        originalLengthFeet: '',
        originalLengthInches: '',
        originalWidthFeet: '',
        originalWidthInches: '',
        originalDepthFeet: '',
        originalDepthInches: '',
        actualLengthFeet: '',
        actualLengthInches: '',
        actualWidthFeet: '',
        actualWidthInches: '',
        actualDepthFeet: '',
        actualDepthInches: '',
        latitude: 37.977432,
        longitude: -87.544054,
        specials: []
    };
    const inputsInitialState = {
            orderDate: '',
            projectNumber: '',
            excavationNumber: '',
            client: null,
            cuts: [{
                steps: [],
                product: '',
                street: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                comments: '',
                monoCurb: null,
                flowableFill: null,
                originalLengthFeet: '',
                originalLengthInches: '',
                originalWidthFeet: '',
                originalWidthInches: '',
                originalDepthFeet: '',
                originalDepthInches: '',
                actualLengthFeet: '',
                actualLengthInches: '',
                actualWidthFeet: '',
                actualWidthInches: '',
                actualDepthFeet: '',
                actualDepthInches: '',
                latitude: 37.977432,
                longitude: -87.544054,
                specials: []
            }]
        };

    let InitialSuccessMessage = "Your changes have been processed successfully."

    const [inputs, setInputs] = useState(inputsInitialState);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState(null);
    const [clientsLoading, setClientsLoading] = useState(true);
    const [clientFuzzySearchSelectItems, setClientFuzzySearchSelectItems] = useState({});
    const [newCut, setNewCut] = useState({});
    const [newSuccessMessage, setNewSuccessMessage] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (id === null || id === undefined) {
            return;
        }

        (async () => {
            setLoading(true);
            setClientsLoading(true);
            const project = await api.fetchProject(id);
            setInputs(project);
            setTimeout(() => {
                setLoading(false);
                setClientsLoading(false);
            }, 1750);
            setNewSuccessMessage(InitialSuccessMessage);
        })();
    }, [id]);

    useEffect(() => {
        if (api.clients !== null) {
            setClients(api.clients);
            setClientsLoading(false);
            return;
        }

        (async () => {
            const response = await api.getClients();
            setClients(response);
            setClientsLoading(false);
        })();
    }, [api.clients]);

    useEffect(() => {
        if (clientsLoading || !clients?.length) {
            return;
        }

        setClientFuzzySearchSelectItems(clients.map(client => ({
            label: client.name,
            value: {id: client.id},
            id: client.id,
        })));
    }, [clients]);

    useEffect(() => {
        if (newCut.id !== undefined) {
            navigate("/projects/" + newCut.id + "/edit");
        }
    }, [newCut]);

    const scrollTop = () => {
        window.scrollTo({ top:0, behavior: 'smooth' });
    }

    const submitHandler = async (values) => {
        setFormSubmitted(true);
        setLoading(true);
        if (!id) {
            const response = await api.postProject(values);
            setNewCut(response);
            setNewSuccessMessage("Your project has been added!")
        } else {
            const response = await api.putProject(values);
            setNewCut(response);
            setNewSuccessMessage(InitialSuccessMessage);
        }

        scrollTop();
        setLoading(false);
        setFormSubmitted(false);
    }

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/project/create"){
            setInputs(inputsInitialState);
            setNewSuccessMessage("");
        }
    }, [location.pathname]);

    return (
        <>
            {loading && clientsLoading && (
                <LoadingOverlay isLighter />
            )}

            {loading && formSubmitted && (
                <LoadingOverlay isLighter>
                    <h3>
                        Projects updated! Redirecting...
                    </h3>
                </LoadingOverlay>
            )}

            <Row gutter={0}>
                <Form
                    id={id}
                    successMessage={newSuccessMessage}
                    values={inputs}
                    setValues={setInputs}
                    errors={errors}
                    setErrors={setErrors}
                    asyncSubmitHandler={(values) => submitHandler(values)}
                >
                    <Row gutter={15}>
                        <Column width={1/2}>
                            <DateField
                                label="Order Date"
                                name="orderDate"
                                value={inputs.orderDate}
                                required
                            />
                        </Column>
                        <Column width={1/2}>
                            <TextField
                                label="Work Order/SAP Number"
                                name="projectNumber"
                            />
                        </Column>
                    </Row>
                    <Row gutter={15}>
                        <Column width={1/2}>
                            <FuzzySearchSelect
                                label="Client"
                                name="client"
                                placeholder="Select One..."
                                items={clientFuzzySearchSelectItems}
                                addChoiceButtonLabel="Add Client"
                                addChoiceForm={AddClientForm}
                                required
                            />
                        </Column>
                        <Column width={1/2}>
                            <TextField
                                label="Excavation Number"
                                name="excavationNumber"
                            />
                        </Column>
                    </Row>
                    <hr />

                    <ArrayField
                        name="cuts"
                        label="Add Cuts"
                        entryLabelSingular="Cut"
                        entryComponent={<CutUpdatedForm cutInitialState={cutInitialState} />}
                        prototypeValue={cutInitialState}
                    />

                    <SubmitButton style={{marginTop:'15px'}}>Submit</SubmitButton>
                </Form>
            </Row>
        </>
    )
  }

export default ProjectForm;
