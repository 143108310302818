import { css } from 'styled-components';

import { getColorFromSlug } from 'utility/colors';

const TextStyles = css`
    body {
        font-family:'Source Sans Pro',sans-serif;
        font-size:16px;
        font-weight:400;
        line-height:1.2;
        color: ${getColorFromSlug('david-black')}
    }

    p {
        font-size: 16px;
        margin: 7px 0;
    }

    a {
        /* color: ${getColorFromSlug('bright-blue')}; */
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family:'Oswald', serif;
        margin:0;
        line-height:1.2;
        font-weight:500;
    }

    h1 {
        font-size:40px;
        margin: 0 0 20px 0;
    }
    h2 {
        font-size:32px;
    }
    h3 {
        font-size:28px;
    }
    h4 {
        font-size:24px;
    }
    h5 {
        font-size:20px;
    }
    h6 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    ul, ol {
        margin-top: 0;
        padding-left: 20px;

        >li {
            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }
    }
`;

export default TextStyles;
