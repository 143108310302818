import styled from 'styled-components';
import { StyledInput } from './AbstractInputField.styles';

export const StyledInputWithAddon = styled.div`
    ${StyledInput} {
        ${({ hasIconLeft }) => hasIconLeft && `        
            padding-left: 58px;
        `}
        ${({ hasIconRight }) => hasIconRight && `        
            padding-right: 58px;
        `}
    }
    
`;

export const StyledInputAddon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    padding: 0 15px;

    ${({ side }) => side === 'left' && `
        left: 0;
    `}

    ${({ side }) => side === 'right' && `
        right: 0;
    `}

    ${({ onClick }) => onClick && `
        cursor: pointer;
    `}

    ${({ onClick }) => !onClick && `
        pointer-events: none;
    `}

    >svg {
        font-size: 28px;
        color: #999;
    }
`;