import MUIDataTable from "mui-datatables";
import { styled } from '@mui/material/styles';
import { getColorFromSlug } from "utility/colors";

// https://mui.com/material-ui/customization/how-to-customize/#2-reusable-component
export const StyledDataTable = styled(MUIDataTable)`
    box-shadow: none;
    border-radius: 0;

    .MuiToolbar-root {
        padding: 0;
        min-height: 48px;
    }

    table:not([class*="MUIDataTableFooter-root"]) {
        border-top: 1px solid ${getColorFromSlug('david-black')};
        border-left: 1px solid ${getColorFromSlug('david-black')};
        border-right: 1px solid ${getColorFromSlug('david-black')};

        th, td {
            color: ${getColorFromSlug('david-black')};
            /* border-right: 1px solid ${getColorFromSlug('david-black')}; */
            border-bottom: 1px solid ${getColorFromSlug('david-black')};
            font-family: 'Source Sans Pro',sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            color: ${getColorFromSlug('david-black')};

            &:not(:last-child) {
                white-space: nowrap;
            }
    
            &:last-child {
                width: 30%;
            }

            &.text-center {
                text-align: center;
            }

            .table-row-actions-container {
                display: flex;
                align-items: center;

                >*:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    
        td {
            padding: 10px 15px;
        }
    
        th {
            background: ${getColorFromSlug('david-yellow')};
            font-family: 'Oswald',serif;
            font-size: 20px;
            line-height: 1.2;
            font-weight: 500;
            padding: 0;
    
            /**
             * This handles header cells that are not sortable (no button).
             */
            >:not([class*="MUIDataTableHeadCell-contentWrapper"]) {
                padding: 10px 15px;
            }
    
            .MuiButton-root {
                width: 100%;
                justify-content: start;
                font-family: 'Oswald',serif;
                font-size: 20px;
                line-height: 1.2;
                font-weight: 500;
                text-transform: none;
                border-radius: 0;
                padding: 10px 15px;
    
                >[class*="MUIDataTableHeadCell-sortAction"] {
                    align-items: center;
    
                    >[class*="MUIDataTableHeadCell-sortAction"] {
                        display: flex;
                        justify-content: flex-end;
                        width: 22px;

                        svg[class*="MuiTableSortLabel-icon"] {
                            margin: 0;
                        }
                    }
                }
            }

            /**
             * Keep text centered even when sorting icon exists on right side.
             */
            &.text-center {
                .MuiButton-root {
                    padding-left: 37px;
                }
            }
        }
    }

    table[class*="MUIDataTableFooter-root"] {
        td {
            border-bottom: 0;
            padding: 0;
        }
    }
`;