import styled from 'styled-components';
// import { getColorFromSlug } from 'utility/colors';

export const StyledModal = styled.div`
    z-index: 100;
    background: white;
    position: relative;
    margin: 50px auto;
    border-radius: 5px;
    max-width: 850px;
    padding: 2rem;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #333;
    opacity: .3;
`;

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 98%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
`;

export const ModalCloseButton = styled.button`
    font-size: 28px;
    font-weight: 400;
    color: gray;
    cursor: pointer;
    border: none;
    background: transparent;
`;

export const ModalBody = styled.div`
    width: 100%;
    font-size: 18px;
`;

export const ModalFooter = styled.div`
    width: 100%;
`

// TableModal //

export const StyledTableModal = styled(StyledModal)`
    max-width: 600px !important;
`

export const TableModalHeader = styled(ModalHeader)`
    margin: 0px !important;
`