import { StyledButton } from 'components/Button/Button.styles';
import { INPUT_HEIGHT } from 'components/Form/FormComponents/Form.styles';
// import { StyledInputWithAddon } from 'components/Form/FormComponents/InputWithAddon.styles';
import styled from 'styled-components';
import { getColorFromSlug } from 'utility/colors';

const OPEN_BOX_SHADOW_CSS = 'box-shadow: 0 0 4px rgba(0,0,0,0.3), -2px 2px 5px rgba(0,0,0,0.2);';

export const StyledFuzzySearchSelect = styled.div`
    ${({ $open }) => $open && `
        ${OPEN_BOX_SHADOW_CSS}
        z-index:2;
    `}
`;

export const StyledToggle = styled.div`
    display: flex;
    align-items: center;
    height: ${INPUT_HEIGHT}px;
    padding: 15px;
    font-size: 18px;
    border: 1px solid black;
    cursor: pointer;
    background: #fff;

    ${({ $open }) => $open && `
        z-index:3;
    `}

    ${({ $hasError }) => $hasError && `
        border: 1px solid ${getColorFromSlug('error-border')};
    `}
`;

export const StyledIconLeftContainer = styled.div`
    flex-shrink: 0;
`;

export const StyledSelectedItemContainer = styled.div`
    flex-grow: 1;
    padding-right: 15px;
`;

export const StyledSelectedItemText = styled.div`
`;

export const StyledStateIndicator = styled.div`
    flex-shrink: 0;
    font-size: 16px;

    ${({ $open }) => $open && `
        transform: rotate(180deg);
    `}
`;

export const StyledExpandable = styled.div`
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: #fff;
    overflow: hidden;
    border: 1px solid black;
    border-top: 0;

    ${({ $open }) => $open && `
        ${OPEN_BOX_SHADOW_CSS}
    `}

    ${({ $open }) => !$open && `
        display: none;
    `}
`;

export const StyledExpandableTop = styled.div`
    display: flex;
    padding: 10px;
    background:#f4f4f4;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    align-items: center;

    ${StyledButton} {
        flex-shrink: 0;
    }
`;

export const StyledSearchInputContainer = styled.div`
    flex-grow: 1;

    >svg {
        position: absolute;
        top: 0; 
        left: 0;
        padding: 17px 0 0 17px;
        font-size: 16px;
        color: #999;
    }
`;

export const StyledItemsContainer = styled.div`
    max-height:300px;
    overflow-y:auto;
`;

export const StyledAddChoiceButtonWrapper = styled.div`
    // box-shadow: 0 0 5px rgba(0,0,0,0.3);

    ${StyledButton} {
        // width: 100%;
        background: ${getColorFromSlug('david-yellow')};
        color: ${getColorFromSlug('david-black')} !important;
        // border-color: transparent;

        &:hover {
            background: ${getColorFromSlug('dark-yellow')};
            // border-color: ${getColorFromSlug('david-black')};
    }
`;

export const StyledAddButton = styled.button`
    width: 100%;
    background: ${getColorFromSlug('david-yellow')};
    color: ${getColorFromSlug('david-black')};
    border: none;
    border-top: 1px solid ${getColorFromSlug('david-black')};
    border-bottom: 1px solid ${getColorFromSlug('david-black')};
    padding: 10px;
    font-size: 16px;
    text-align: center;

    &:hover {
        background: #f4f4f4;
        border: none;
        border-top: 1px solid ${getColorFromSlug('david-black')};
        border-bottom: 1px solid ${getColorFromSlug('david-black')};
    }

    &:focus {
        background: #f4f4f4;
        border: none;
        border-top: 1px solid ${getColorFromSlug('david-black')};
        border-bottom: 1px solid ${getColorFromSlug('david-black')};
    }

    >svg {
        color: ${getColorFromSlug('david-black')};
        font-size 16px;
        padding-right: 5px;
        margin-left: -10px;
    }
`

