import styled from "styled-components";
import { getColorFromSlug } from "utility/colors";

const TITLE_BAR_HEIGHT = 30;

export const StyledArrayFieldEntry = styled.div`
    margin-bottom: 10px;
`;

export const StyledArrayFieldEntryTitleBar = styled.div`
    display: flex;
    align-items: center;
    height: ${TITLE_BAR_HEIGHT}px;
    background: ${getColorFromSlug('david-black')};
    border: 1px solid ${getColorFromSlug('david-black')};
`;

export const StyledArrayFieldEntryTitle = styled.div`
    flex-grow: 1;
    color: #fff;
    padding: 0 10px;
`;

export const StyledArrayFieldEntryTitleBarCloseTrigger = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${TITLE_BAR_HEIGHT}px;
    height: 100%;
    font-size: 22px;
    color: #fff;
    opacity: 0.7;
    cursor: pointer;

    &:hover, &:focus, &:active {
        background: ${getColorFromSlug('dark-yellow')};
        color: ${getColorFromSlug('david-black')};
        opacity: 1;
    }
`;

export const StyledArrayFieldEntryFields = styled.div`
    padding: 10px;
    border: 1px solid ${getColorFromSlug('david-black')};
    border-top: 0;
`;