import {createGlobalStyle} from 'styled-components';
import {getColorFromSlug} from 'utility/colors';
import ImageStyles from 'styles/images';
import TextStyles from 'styles/text';

const GlobalStyle = createGlobalStyle`
    * {
        position: relative;
        box-sizing: border-box;

        &:before, &:after {
            box-sizing: border-box;
        }
    }

    html {
        // overflow-x: hidden;
    }

    body {
        margin: 0;
        padding: 0;
        max-width: 100vw;
        overflow-x: hidden;
    }
    
    .login {
      height: 100vh;
      width: 100vw;
      background-color: ${getColorFromSlug('david-black')};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 10px solid ${getColorFromSlug('david-yellow')};
    }
    
    .login .logoLink {
      height: 20% !important;
    }

    hr {
        margin: 15px 0;
        border: 0;
        border-top: 1px solid #ddd;
    }

    .leaflet-div-icon {
        background: transparent;
        border: none;
        color: ${getColorFromSlug('david-black')};
        font-size: 28px;
        text-align: center;
    
        &:hover {
            color: ${getColorFromSlug('dark-yellow')};
        }

        &:focus {
            color: ${getColorFromSlug('dark-yellow')};
        }
    }

    .auto-complete-input {
        padding: 15px;
        width: 100%;
        font-size: 18px;
        margin: 0;
    }

    ${ImageStyles}
    ${TextStyles}

    .custom-marker-cluster{
        background: ${getColorFromSlug('cluster-fill')};
        border: 7px solid rgba(38, 150, 255, .7);
        border-radius: 50%;
        outline: 6px solid rgba(128, 194, 255, .7);
        color: #fff;
        height: 30px;
        width: 30px;
        line-height: 27px;
        text-align: center; 
      }

    .filter-row {
        display: flex;
        width: 100%;
        align-items: center;
    }
`;

export default GlobalStyle;
