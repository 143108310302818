import React, { useState } from 'react';
import Form from './FormComponents/Form';
import Row from 'components/Grid/Row';
import Column from 'components/Grid/Column';
import { useDavidApi } from 'utility/DavidApi/DavidApi';
import Button from 'components/Button/Button';
import LoadingOverlay from 'components/Layout/MapView/LoadingOverlay/LoadingOverlay';
import { SubmitButton, YellowButtonWrapper } from 'components/Button/Button.styles';

function DeleteClientForm({
    client, 
    hideModal,
    ...otherProps
}) {
    const api = useDavidApi();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const submitHandler = () => {
        (async () => {
            setLoading(true);
            await api.deleteClient(client.id);
            
            setTimeout(() => {
                hideModal();
                setLoading(false);
            }, 
                1500
            );
            
        })();
    }

    return (
        <Form 
            successMessage='Client Deleted!'
            id={client.id} 
            asyncSubmitHandler={submitHandler}
            errors={errors}
            setErrors={setErrors}
            {...otherProps}
        >
        {loading && (
            <LoadingOverlay isLighter>
                <h2>
                    Client deleted! Refreshing...
                </h2>
            </LoadingOverlay>
        )}
            <p>Are you sure you want to delete client "<strong>{client.name}</strong>"?</p>
            <Row gutter={25} style={{width:'75%', marginTop:'30px'}}>
                <Column width={1/2}>
                    <YellowButtonWrapper>
                        <SubmitButton>
                            Yes, Delete
                        </SubmitButton>
                    </YellowButtonWrapper>
                </Column>
                <Column width={1/2}>
                    <Button onClick={hideModal}>
                        No, Don't Delete
                    </Button>
                </Column>
            </Row>
        </Form>
    )
  }

export default DeleteClientForm;
