import {forwardRef, useContext, useEffect, useState} from "react";
import {FormContext} from "./Form";
import FormField from "./FormField";
import get from 'lodash.get';
import {ArrayFieldEntryContext} from "./ArrayFieldEntry";
import { StyledInput } from "./AbstractInputField.styles";


const AbstractInputField = forwardRef( ({
    label = null,
    type,
    required = false,
    name = null,
    value,
    onChange,
    id = null,
    hasError = false,
    errorMessage,
    placeholder,
    InputComponent = StyledInput,
    inputComponentProps,
    isFormField = true,
    beforeChangeEvent,
    afterChangeEvent,
    adjustValue,
    ...otherProps
}, ref) => {
    const formContext = useContext(FormContext);
    const arrayFieldEntryContext = useContext(ArrayFieldEntryContext);
    const [adjustedName, setAdjustedName] = useState(name);
    const [adjustedId, setAdjustedId] = useState(id);

    const inputChangeHandler = (e) => {
        if (beforeChangeEvent && typeof beforeChangeEvent === 'function') {
            beforeChangeEvent(e);
        }

        let adjustedValue = e.target.value;

        if ( typeof adjustValue === 'function' ) {
            adjustedValue = adjustValue(e.target.value);
        }

        formContext.inputChangeHandler(adjustedName, adjustedValue);

        if (afterChangeEvent && typeof afterChangeEvent === 'function') {
            afterChangeEvent(e);
        }
    }

    if (isFormField) {
        if (value === '' || value === undefined) {
            value = get(formContext.values, adjustedName, '');
        }

        errorMessage = get(formContext.errors, adjustedName, null);
        hasError = !!errorMessage;

        if (onChange === undefined) {
            onChange = e => inputChangeHandler(e);
        }
    }

    useEffect(() => {
        if (!isFormField) return;

        if (arrayFieldEntryContext) {
            setAdjustedName(`${arrayFieldEntryContext.name}.${name}`);
        }
    }, [arrayFieldEntryContext]);

    useEffect(() => {
        if (!isFormField) return;

        if (!id && adjustedName) {
            setAdjustedId(adjustedName);
        }
    }, [adjustedName]);

    return (
        <FormField label={label} required={required} id={id} errorMessage={errorMessage} {...otherProps}>
            <InputComponent
                type={type}
                id={adjustedId}
                name={adjustedName}
                $hasError={hasError}
                required={required}
                placeholder={placeholder}
                ref={ref}
                value={value}
                onChange={onChange}
                {...inputComponentProps}
            />
        </FormField>
    );
} );

export default AbstractInputField;
