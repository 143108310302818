import React, {useContext, useMemo, useRef, useState} from 'react';

import {renderToStaticMarkup} from 'react-dom/server';
import {divIcon} from 'leaflet';
import {Marker, Popup} from 'react-leaflet';
// import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FormContext} from "../../Form/FormComponents/Form";
import {ArrayFieldEntryContext} from "../../Form/FormComponents/ArrayFieldEntry";

function CutMarker({coordinates, id, address, street, city, state, zipcode, markerDragendHandler}) {
    const formContext = useContext(FormContext);
    const cutFieldEntryContext = useContext(ArrayFieldEntryContext);

    const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon icon={icon({name: 'location-pin', style: 'solid'})} />);
    const customMarkerIcon = divIcon({
        html: iconMarkup,
        iconSize: [35, 35],
    });

    const markerRef = useRef(null);
    const [draggable, setDraggable] = useState(true);

    // useEffect(() => {
    //     setPosition(coordinates);
    // }, [coordinates]);

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                // alert('dragend');
                // setDraggable(false);
                const marker = markerRef.current
                if (marker != null) {
                    formContext.inputChangeHandler(cutFieldEntryContext.name + `.latitude`, marker.getLatLng()[0]);
                    formContext.inputChangeHandler(cutFieldEntryContext.name + `.longitude`, marker.getLatLng()[1]);

                    if (markerDragendHandler) {
                        markerDragendHandler(marker.getLatLng());
                    }
                }
            },
        }),
        [],
    )

    // const callback = useCallback(() => {
    //     alert("Long pressed!");
    //     setDraggable(true);
    //   }, []);

    //   const bind = useLongPress(callback, {
    //     // onStart: (event, meta) => console.log("Press started", meta),
    //     // onFinish: (event, meta) => console.log("Long press finished", meta),
    //     // onCancel: (event, meta) => console.log("Press cancelled", meta),
    //     //onMove: () => console.log("Detected mouse or touch movement"),
    //     filterEvents: (event) => true, // All events can potentially trigger long press
    //     threshold: 500,
    //     // captureEvent: true,
    //     cancelOnMovement: true,
    //     detect: LongPressDetectEvents.BOTH
    //   });

    // const {isShowing, toggle} = useModal();

    return (
      <Marker
        key={id}
        icon={customMarkerIcon}
        ref={markerRef}
        eventHandlers={eventHandlers}
        position={coordinates}
        draggable={draggable}
        // {...bind()}
      >
          <Popup minWidth={150}>
              <p>{address}</p>
              <p>{street} {city}, {state} {zipcode}</p>
              {/* <Row gutter={10}>
                  <Column width={1/2}>
                      <a href="/test">
                          <ExternalLink target="_blank" to={'https://www.google.com/maps/dir/?api=1&destination=' + coordinates.join(',')}>
                              Directions
                          </ExternalLink>
                      </a>
                  </Column>
                  <Column width={1/2}>
                      <Button onClick={toggle}>
                          Edit
                      </Button>
                      <Modal
                          isShowing={isShowing}
                          hide={toggle}
                      />
                  </Column>
              </Row> */}
          </Popup>
      </Marker>
  );
}

export default CutMarker;
