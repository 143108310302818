import React, { useState, useEffect } from 'react';
import {MapContainer, TileLayer, useMap, ZoomControl, useMapEvents} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import MapMarker from "./MapMarker";
import L, {MarkerCluster} from "leaflet";
import './DateLegend.css';
import CutLegendControl from './CutLegendControl/CutLegendControl';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {duotone} from '@fortawesome/fontawesome-svg-core/import.macro';

function UpdateMapView({ coordinates, mapZoom, cuts }) {
    const map = useMap();

    useEffect(() => {
        if (cuts !== 0) {
            map.setView(coordinates, mapZoom);
        } else if (cuts === 0) {
            map.setView(coordinates, mapZoom);
        }
    }, [map, coordinates, cuts]);

    return null;
}
function DateLegendControl() {
    return (
        <div className='leaflet-bottom leaflet-right'>
            <div className="leaflet-control info legend">
                    <p style={{marginLeft: '8px', marginRight: '10px'}}><i style={{background: '#ff4a26'}} key={'legendRed'}></i> older than 30 days <br/></p>
                    <p style={{marginLeft: '8px', marginRight: '10px'}}><i style={{background: '#ffbc03'}} key={'legendOrange'}></i> older than 7 days<br/></p>
                    <p style={{marginLeft: '8px', marginRight: '10px'}}><i style={{background: '#2da322'}} key={'legendGreen'}></i> current</p>
                    <p style={{marginLeft: '8px', marginRight: '10px'}}><i style={{background: '#0065c4'}} key={'legendBlue'} />cluster</p>
            </div>
        </div>
    )
}

function TitleControl({title, subtitle}) {
    return (
        <div className='leaflet-top leaflet-center'>
            <div className="leaflet-control info legend">
                <h2><FontAwesomeIcon icon={duotone('eye')} style={{marginRight: '8px', color: '#0065c4'}} />{title}</h2>
            </div>
            {subtitle && (
                <div className="leaflet-control info legend">
                    <h4>{subtitle}</h4>
                </div>
            )}
        </div>
    )
}

// function IconLegendControl() {
//     return (
//         <div className='leaflet-bottom leaflet-left'>
//             <div className='leaflet-control info legend'>
//                     <p><FontAwesomeIcon icon={icon({name: 'person-digging', style: 'solid'})} style={{fontSize:'18px'}} /> Digout</p>
//                     <p><FontAwesomeIcon icon={icon({name: 'mound', style: 'solid'})} style={{fontSize:'18px'}} /> Base</p>
//                     <p><FontAwesomeIcon icon={icon({name: 'truck', style: 'solid'})} style={{fontSize:'18px'}} /> Surface</p>
//                     <p><FontAwesomeIcon icon={icon({name: 'square-check', style: 'solid'})}style={{fontSize:'18px'}} /> Complete</p>
//             </div>
//         </div>
//     )
// }


const createClusterCustomIcon = function (cluster = MarkerCluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(40, 40, true),
    })
  }

function MapView({ 
    cuts, 
    refreshCuts, 
    loading, 
    setLoading, 
    title, 
    handleFilterSubmit, 
    inputs, 
    setInputs, 
    initialState, 
    centerCoordinates, 
    newZoom, 
    subtitle, 
    // sidebarFilterSubmitted, 
    // setSidebarFilterSubmitted 
}) {

  return (
    <MapContainer center={centerCoordinates} zoom={newZoom} zoomControl={false} scrollWheelZoom={true} maxZoom={18} style={{ height:'calc(100vh - 70px)', width:'100%'}} dragging={true}>
        <UpdateMapView coordinates={centerCoordinates} mapZoom={newZoom} cuts={cuts?.length} />
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={50}
            spiderfyOnMaxZoom={true}
            chunkedLoading={true}
            showCoverageOnHover={false}
        >
        {!loading && cuts?.length && cuts.map(cut => (
            <MapMarker
                coordinates={[cut.latitude, cut.longitude]}
                id={cut.id}
                address={cut.address}
                street={cut.street}
                city={cut.city}
                state={cut.state}
                zipcode={cut.zipcode}
                client={cut?.project?.client?.name}
                date={cut.project.orderDate}
                weight={cut.calculateOriginalUOM + ' ' + cut.unit}
                key={'marker' + cut.id}
                projectNumber={cut.project.projectNumber}
                orderDate={cut.project.orderDate}
                originalDepth={cut.originalDepth}
                originalLength={cut.originalLength}
                originalWidth={cut.originalWidth}
                actualDepth={cut.actualDepth}
                actualLength={cut.actualLength}
                actualWidth={cut.actualWidth}
                comments={cut.comments}
                steps={cut.steps.map(step => (
                       <span>{step.label}</span>
                ))}
                cut={cut}
                refreshCuts={refreshCuts}
                setLoading={setLoading}
            />
        ))}
        </MarkerClusterGroup>
        <TitleControl title={title} subtitle={subtitle} />
        <ZoomControl position='topright'/>
        <DateLegendControl />
        {/* <IconLegendControl /> */}
        <CutLegendControl 
            handleFilterSubmit={handleFilterSubmit} 
            cuts={cuts}
            inputs={inputs}
            setInputs={setInputs}
            refreshCuts={refreshCuts}
            initialState={initialState}
            // sidebarFilterSubmitted={sidebarFilterSubmitted}
            // setSidebarFilterSubmitted={setSidebarFilterSubmitted}
        />
    </MapContainer>
  );
}

export default MapView;
