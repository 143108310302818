const colors = {
    'david-yellow': '#fadf00',
    'david-black': '#050708',
    'error-border': '#bf282d',
    'error-bg': '#fff4f4',
    'error-text': '#bf282d',
    'success-border': '#188429',
    'success-bg': '#f7fbf8',
    'success-text': '#188429',
    'info-border': '#287bad',
    'info-bg': '#f4f8fb',
    'info-text': '#287bad',
    'dark-yellow': '#ffcd05',
    'light-yellow': '#fff285',
    'map-red': '#ff4a26',
    'dark-red': '#db310f',
    'map-green': '#2da322',
    'cluster-fill': '#0065c4',
    'dark-gray': '#343c40',
};

export function getColorFromSlug(slug) {
    return colors[slug] || null;
}

export function getColor(color) {
    return getColorFromSlug(color) || color;
}
