import {createTheme, ThemeProvider} from '@mui/material/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro'


import {StyledDataTable} from "./DataTable.styles";

/**
 * Examples full page: https://439oorwx3w.sse.codesandbox.io/
 * Examples with code: https://codesandbox.io/s/github/gregnb/mui-datatables
 */

// https://github.com/gregnb/mui-datatables#options
const DEFAULT_OPTIONS = {
    // filterType: 'checkbox',
    download: false,
    print: false,
    // search: false,
    viewColumns: false,
    // searchOpen: true,
    // searchAlwaysOpen: true,
    searchPlaceholder: "Begin typing to search...",
    // elevation: 4,
    fixedHeader: true,
    // jumpToPage: true,
    // responsive: 'simple',
    rowsPerPageOptions: [10, 25, 50, 100],
    selectableRows: 'none',
    // serverSide: true, // requires testing
    // sortOrder: {
    //     name: 'name',
    //     direction: 'asc',
    // }
};

// https://github.com/gregnb/mui-datatables#customize-styling
const dataTableTheme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple
            },
        },
        // MuiTableCell: {
        //     styleOverrides: {
        //         root: {
        //             padding: '10px 15px',
        //         },
        //     }
        // },
        // MUIDataTableHeadCell: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: getColorFromSlug('david-yellow'),
        //         },
        //         // toolButton: {
        //         //     backgroundColor: 'red !important',
        //         // },
        //         // toolButton: {
        //         //     color: getColorFromSlug('david-black'),
        //         //     fontFamily: "'Oswald', serif",
        //         //     margin: 0,
        //         //     lineHeight: 1.2,
        //         //     fontWeight: 500,
        //         // }
        //     }
        // },
        // MUIDataTableBodyCell: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: "#FF0000"
        //         }
        //     }
        // }
    }
})

const DataTable = ({
    columns = [],
    data = [],
    options = {},
    loading = false,
    ...otherProps
}) => {
    return (
        <ThemeProvider theme={dataTableTheme}>
            {loading && (
                <div style={{
                    display:"flex",
                    justifyContent:"center",
                    padding:"30px",
                    background:"#eee",
                    color: "#888",
                }}>
                    <FontAwesomeIcon icon={regular('spinner-third')} className="fa-spin fa-3x" />
                </div>
            )}

            {!loading && (
                <StyledDataTable
                    data={data}
                    columns={columns}
                    options={{...DEFAULT_OPTIONS, ...options}}
                    {...otherProps}
                />
            )}
        </ThemeProvider>
    )
}

export default DataTable;
