import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { MobileMenuToggle, LogoLink, NavWrapper, MobileMenuOverlay, HeaderBuffer, StyledHeader, HeaderContainer } from './Header.styles.js';
import { disableBodyScroll, enableBodyScroll } from 'utility/bodyScroll.js';
import { getColorFromSlug } from 'utility/colors.js';

import { ReactComponent as Logo } from 'images/logo.svg';
import Nav from 'components/Layout/Nav/Nav.js';
// import { useLocation } from 'react-router-dom';

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    /////////////////////////////////////////////////////////////////////////////////
    // TODO: make mobile menu close whenever navigation occurs.
    // Suggestions: https://stackoverflow.com/a/71229097
    // (the code directly below this doesn't seem to work, must do something else)

    // const location = useLocation();
    // useEffect(() => {
    //     setMobileMenuOpen(false);
    // }, location)
    /////////////////////////////////////////////////////////////////////////////////

    // TODO: close mobile menu when screen width becomes wide enough to not need it
    // (currently, if the menu is open and the width increases, the scroll bar remains disabled)

    const openMobileMenu = () => {
        disableBodyScroll();
        setMobileMenuOpen(true);
        document.addEventListener('keydown', handleKeydown);
    }

    const closeMobileMenu = () => {
        enableBodyScroll();
        setMobileMenuOpen(false);
        document.removeEventListener('keydown', handleKeydown);
    }

    const handleKeydown = (e) => {
        switch(e.key) {
            case('Escape'):
                closeMobileMenu();
            break;
            default:
            break;
        }
    }

    return (
        <>
            <HeaderBuffer />
            <StyledHeader>
                <HeaderContainer>
                    <MobileMenuToggle onClick={openMobileMenu}>
                        <FontAwesomeIcon icon={regular('bars')} />
                    </MobileMenuToggle>
                    <LogoLink to="/">
                        <Logo style={{ fill: getColorFromSlug('david-black') }} />
                    </LogoLink>
                    <NavWrapper>
                        <Nav open={mobileMenuOpen} mobileMenuCloseHandler={closeMobileMenu} />
                    </NavWrapper>
                    <MobileMenuOverlay open={mobileMenuOpen} onClick={closeMobileMenu} />
                </HeaderContainer>
            </StyledHeader>
        </>
    );
}

export default Header;