import AbstractInputField from "./AbstractInputField";
import { StyledTextarea } from "./TextareaField.styles";

const TextareaField = ({
    rows,
    ...otherProps
}) => {
    const inputComponentProps = {rows};

    return (
        <AbstractInputField
            InputComponent={StyledTextarea}
            inputComponentProps={inputComponentProps} 
            {...otherProps}
        />
    );
}

export default TextareaField;
