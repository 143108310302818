import React from 'react';
import {StyledCutLegend} from './FormComponents/Form.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import TextField from './FormComponents/TextField';
import NumberField from './FormComponents/NumberField';

function SpecialForm() {
     return (
        <Row>
            <StyledCutLegend>
                <Row gutter={15}>
                    <Column width={1/2}>
                        <TextField
                            label="Name"
                            name="name"
                        />
                    </Column>

                    <Column width={1/2}>
                        <NumberField
                            label="Quantity"
                            name="quantity"
                        />
                    </Column>
                </Row>
            </StyledCutLegend>
        </Row>
    )
  }

export default SpecialForm;
