import React from 'react';
import Row from "components/Grid/Row";
import FeetInchesForm from "./FeetInchesForm";
import CheckboxField from "./FormComponents/CheckboxField";

function MeasurementForm({fieldName, cut, product}) {
     return (
         <div>
             <CheckboxField
                 label="Flowable Fill"
                 name={fieldName + '.flowableFill'}
             />
            {product === 1 && (
            <>
             <h3 style={{marginTop:"10px", marginBottom:'15px'}}>Original Measurements</h3>
             <Row gutter={15}>
                 <FeetInchesForm label="Length" uniqueName="originalLength" fieldName={fieldName} cut={cut} />
                 <FeetInchesForm label="Width" uniqueName="originalWidth" fieldName={fieldName} cut={cut} />
                 <FeetInchesForm label="Depth" uniqueName="originalDepth" fieldName={fieldName} cut={cut} />
             </Row>
             </>
            )}
             <h3 style={{marginTop:"15px", marginBottom:'15px'}}>Actual Measurements</h3>
             <Row gutter={15}>
                 <FeetInchesForm label="Length" uniqueName="actualLength" fieldName={fieldName} cut={cut} />
                 <FeetInchesForm label="Width" uniqueName="actualWidth" fieldName={fieldName} cut={cut} />
                 <FeetInchesForm label="Depth" uniqueName="actualDepth" fieldName={fieldName} cut={cut} />
             </Row>
         </div>
    )
  }

export default MeasurementForm;
