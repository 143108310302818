import React, { useState } from 'react';
import { SubmitButton } from 'components/Button/Button.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import TextField from './FormComponents/TextField';
import Form from './FormComponents/Form';
import LoadingOverlay from 'components/Layout/MapView/LoadingOverlay/LoadingOverlay';
import { useDavidApi } from 'utility/DavidApi/DavidApi';

const AddClientForm = ({
    client = {},
    hideModal,
    ...otherProps
}) => {
    const api = useDavidApi();
    const [inputs, setInputs] = useState(client);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // let submitHandler = api.postClient;

    const submitHandler = () => {
        (async () => {
            setLoading(true);
            await api.postClient(inputs);

            setTimeout(() => {
                hideModal();
                setLoading(false);
            }, 
                1500
            );
        })();
    }

    if (client?.id) {
        submitHandler = () => {
            (async () => {
                await api.patchClient(inputs); 
                setTimeout(() => {
                    hideModal()
                }, 
                    2000
                );  
            })();
        }
    }

    return (
        <Form
            successMessage='Client Added!'
            values={inputs}
            setValues={setInputs}
            asyncSubmitHandler={submitHandler}
            errors={errors}
            setErrors={setErrors}
            {...otherProps}
        >
        {loading && (
            <LoadingOverlay>
                <h2>
                    Client added! Refreshing...
                </h2>
            </LoadingOverlay>
        )}
            <TextField
                label="Client Name"
                name="name"
                required
            />

            <TextField
                label="Address"
                name="address"
            />

            <TextField
                label="Address Line 2"
                name="address1"
            />

            <Row gutter={15}>
                <Column width={1/3}>
                    <TextField
                        label="City"
                        name="city"
                    />
                </Column>
                <Column width={1/3}>
                    <TextField
                        label="State"
                        name="state"
                    />
                </Column>
                <Column width={1/3}>
                    <TextField
                        label="Zipcode"
                        name="zipcode"
                    />
                </Column>
            </Row>
            <Row gutter={15}>
                <Column width={1/2}>
                    <TextField
                        label="Phone"
                        name="phone"
                        required
                    />
                </Column>
                <Column width={1/2}>
                    <TextField
                        label="Fax"
                        name="fax"
                    />
                </Column>
            </Row>

            <SubmitButton>Submit</SubmitButton>
        </Form>
    )
  }

export default AddClientForm;
