import React, {useContext, useEffect, useState} from 'react';
import Column from "components/Grid/Column";
import NumberField from './FormComponents/NumberField';
import Row from 'components/Grid/Row';
import {FormContext} from "./FormComponents/Form";

function FeetInchesForm({label, uniqueName, fieldName = '', cut}) {
    const formContext = useContext(FormContext);
    const [feet, setFeet] = useState(0);
    const [inches, setInches] = useState(0);

    const changeInchesToFeet = (inches) => {
        return Math.floor(inches/12);
    }

    const changeInchesToInches = (inches) => {
        return inches%12;
    }

    const calculateTotalInches = (feet, inches) => {
        return feet*12 + inches;
    }

    useEffect(() => {
        setFeet((currentFeet) => (cut?.[uniqueName] ? changeInchesToFeet(cut[uniqueName]) : 0));
        setInches((currentInches) => (cut?.[uniqueName] ? changeInchesToInches(cut[uniqueName]) : 0));
    }, [cut[uniqueName]]);

    const updateState = (e, isFeet = false) => {
        const value = parseInt(e.target.value);
        let totalInches = 0;

        if (isFeet) {
            setFeet(value);
            totalInches = calculateTotalInches(value, inches);
        } else {
            setInches(value);
            totalInches = calculateTotalInches(feet, value);
        }

        if (fieldName === '') {
            formContext.inputChangeHandler(uniqueName, totalInches);
        } else {
            formContext.inputChangeHandler(fieldName + '.' + uniqueName, totalInches);
        }

    }

     return (
        <Column width={1/3}>
            <h5 style={{fontSize: '22px', marginBottom: '5px'}}>{label}:</h5>
            <Row>
                <NumberField
                    label={`ft.`}
                    onChange={(e) => {
                        updateState(e, true)
                    }}
                    value={feet}
                    style={{width: '100px', marginRight: '4px'}}
                />
                <NumberField
                    label={`in.`}
                    onChange={(e) => {
                        updateState(e)
                    }}
                    value={inches}
                    style={{width: '100px'}}
                />
             </Row>
         </Column>
    )
  }

export default FeetInchesForm;
