import AbstractInputField from "./AbstractInputField";

const NumberField = ({
    ...otherProps
}) => {
    const adjustValue = (val) => {
        let numberValue = Number(val);

        if ( isNaN(numberValue) ) {
            return null;
        }

        return numberValue;
    }

    return (
        <AbstractInputField type="number" adjustValue={adjustValue} {...otherProps} />
    );
}

export default NumberField;
