import React, { useState } from 'react';
import {SubmitButton} from 'components/Button/Button.styles';
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import Form from './FormComponents/Form';
import Row from 'components/Grid/Row';
import Column from 'components/Grid/Column';
import Button from 'components/Button/Button';
import LoadingOverlay from 'components/Layout/MapView/LoadingOverlay/LoadingOverlay';
import { YellowButtonWrapper } from 'components/Button/Button.styles';

function ReviewCutForm({
   cut,
   hideModal,
   reviewCutsParameters,
   ...otherProps
}) {
    const api = useDavidApi();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const submitHandler = () => {
      (async () => {
          setLoading(true);
          await api.putReview(cut.id, reviewCutsParameters);

          setTimeout(() => {
              hideModal();
              setLoading(false);
          }, 
              1500
          );
      })();
  }

    return (
      <Form
          successMessage='Approved!'
          id={cut.id}
          errors={errors}
          setErrors={setErrors}
          asyncSubmitHandler={submitHandler}
          {...otherProps}
      >
        {loading && (
            <LoadingOverlay isLighter>
                <h2>
                    Cut approved! Refreshing...
                </h2>
            </LoadingOverlay>
        )}
        <p>Are you sure you want to approve cut #{cut.id} at {cut.address} {cut.street}?</p>
        <Row gutter={25} style={{width:"75%", marginTop:"30px"}}>
          <Column width={2/4}>
            <YellowButtonWrapper>
              <SubmitButton>Approve</SubmitButton>
            </YellowButtonWrapper>
          </Column>
          <Column width={2/4}>
            <Button onClick={hideModal} >Cancel</Button>
          </Column>
        </Row>
      </Form>
    )
  }

export default ReviewCutForm;
