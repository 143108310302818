import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import get from 'lodash.get';

import {
    StyledAddChoiceButtonWrapper,
    StyledExpandable,
    StyledExpandableTop,
    StyledFuzzySearchSelect,
    StyledIconLeftContainer,
    StyledItemsContainer,
    StyledSearchInputContainer,
    StyledSelectedItemContainer,
    StyledSelectedItemText,
    StyledStateIndicator,
    StyledToggle
} from "./FuzzySearchSelect.styles";
import {useContext, useEffect, useRef, useState} from 'react';
import FuzzySearchInput from './FuzzySearchInput';
import {createIndex, searchIndex} from 'utility/fuzzySearch';
import FuzzySearchSelectItem from './FuzzySearchSelectItem';
import FormField from 'components/Form/FormComponents/FormField';
import Modal from 'components/Modal/Modal';
import useModal2 from 'components/Modal/useModal2';
import {FormContext} from 'components/Form/FormComponents/Form';
import {ArrayFieldEntryContext} from 'components/Form/FormComponents/ArrayFieldEntry';
import Button from 'components/Button/Button';


const FuzzySearchSelect = ({
    label = null,
    required = false,
    id = null,
    name = null,
    value,
    onChange,
    iconLeft = null,
    items = [],
    placeholder = '',
    hasError = false,
    errorMessage,
    isFormField = true,
    addChoiceForm,
    addChoiceButtonLabel = 'Add',
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [visibleItemKeys, setVisibleItemKeys] = useState([]);
    const [visibleItemIndexesByItemKey, setVisibleItemIndexesByItemKey] = useState({});
    const [hoveredItemKey, setHoveredItemKey] = useState(null);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    // const [selectedItemKey, setSelectedItemKey] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [fuzzySearchMatchIndicesByItemId, setFuzzySearchMatchIndicesByItemId] = useState({});
    const [placeholderIsVisible, setPlaceholderIsVisible] = useState(true);
    const [canCloseViaEscKey, setCanCloseViaEscKey] = useState(true);
    const [fuzzySearchSelectItemsByItemId, setFuzzySearchSelectItemsByItemId] = useState(null);
    const fuzzySearchSelectRef = useRef(null);
    const fuzzySearchInputRef = useRef(null);
    const [addChoiceModalIsShowing, toggleAddChoiceModal] = useModal2();
    const formContext = useContext(FormContext);
    const arrayFieldEntryContext = useContext(ArrayFieldEntryContext);
    const [adjustedName, setAdjustedName] = useState(name);
    const [itemKeyToSelectAfterNextItemsUpdate, setItemKeyToSelectAfterNextItemsUpdate] = useState(null);
    // const [valueState, setValueState] = useState(value);
    // const [onChangeState, setOnChangeState] = useState(onChange);

    const AddChoiceFormComponent = addChoiceForm;

    if (isFormField) {
        value = get(formContext.values, adjustedName, null);
        errorMessage = get(formContext.errors, adjustedName, null);
        hasError = !!errorMessage;
        onChange = () => formContext.inputChangeHandler(adjustedName, selectedItem?.value);
    }


    // useEffect(() => {
    //     if (!isFormField) return;

    //     setValueState( get(formContext.values, adjustedName, '') );
    //     setOnChangeState( () => formContext.inputChangeHandler(adjustedName, selectedItem) );
    // }, [isFormField]);

    useEffect(() => {
        if (!isFormField) return;

        if (arrayFieldEntryContext) {
            setAdjustedName(`${arrayFieldEntryContext.name}.${name}`);
        }
    }, [arrayFieldEntryContext]);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const handleClickOutside = (e) => {
        if ( fuzzySearchSelectRef.current && !fuzzySearchSelectRef.current.contains(e.target) ) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (fuzzySearchInputRef.current && isOpen) {
            fuzzySearchInputRef.current.focus();
        }

        // for an extensible, reusable component that listens for clicks outside of itself, see this: https://stackoverflow.com/a/42234988
        if (fuzzySearchSelectRef.current && isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        if (fuzzySearchSelectRef.current && !isOpen) {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [isOpen])

    const fuzzySearchItemsIndex = createIndex(items, ['label'], {
        shouldSort: true,
    });

    const doFuzzySearch = (q) => {
        // console.log('doFuzzySearch()');
        if (!q) {
            setVisibleItemKeys( items?.length ? items.map(item => item.id) : [] );
            setFuzzySearchMatchIndicesByItemId({});
            setPlaceholderIsVisible(true);
            setCanCloseViaEscKey(true);
            return;
        }

        const results = searchIndex(fuzzySearchItemsIndex, q);

        if (!results.length) {
            setVisibleItemKeys([]);
            return;
        }

        let newVisibleItemKeys = [];
        let newFuzzySearchMatchIndicesByItemId = {};
        for (const result of results) {
            newVisibleItemKeys.push(result.item.id);
            newFuzzySearchMatchIndicesByItemId[result.item.id] = result.matches[0].indices;
        }

        // setVisibleItemKeys( results.map(result => result.item.id) );

        setVisibleItemKeys(newVisibleItemKeys);
        setFuzzySearchMatchIndicesByItemId(newFuzzySearchMatchIndicesByItemId);
        setPlaceholderIsVisible(false);
        setCanCloseViaEscKey(false);
    }

    useEffect(() => {
        // console.log('visibleItemKeys =', visibleItemKeys);
        setHoveredItemKey(placeholderIsVisible ? null : visibleItemKeys?.[0]);

        let newVisibleItemIndexesByItemKey = {};

        for (const i in visibleItemKeys) {
            const visibleItemKey = visibleItemKeys[i];
            newVisibleItemIndexesByItemKey[visibleItemKey] = parseInt(i);
        }

        setVisibleItemIndexesByItemKey(newVisibleItemIndexesByItemKey);
    }, [visibleItemKeys])

    useEffect(() => {
        if (!fuzzySearchSelectItemsByItemId) {
            return;
        }

        if (value && value !== selectedItem?.value) {
            // selectItem(value?.id);
            selectItem(value);
            return;
        }

        if (itemKeyToSelectAfterNextItemsUpdate && itemKeyToSelectAfterNextItemsUpdate !== selectedItem?.id) {
            selectItem(itemKeyToSelectAfterNextItemsUpdate);
            setItemKeyToSelectAfterNextItemsUpdate(null);

            if (addChoiceModalIsShowing) {
                toggleAddChoiceModal();
            }

            return;
        }

    }, [fuzzySearchSelectItemsByItemId])

    const hoverItem = (itemKey) => {
        setHoveredItemKey(itemKey);
    }

    const selectItem = (itemKey) => {
        if (!fuzzySearchSelectItemsByItemId) {
            return;
        }

        setSelectedItem( fuzzySearchSelectItemsByItemId?.[itemKey] );
    }

    const selectHoveredItem = () => {
        selectItem(hoveredItemKey);
    }

    useEffect(() => {
        if (!items?.length) {
            return;
        }

        let newFuzzySearchSelectItemsByItemId = {};

        if (items?.length) {
            for (const item of items) {
                newFuzzySearchSelectItemsByItemId[item.id] = item;
            }
        }

        setFuzzySearchSelectItemsByItemId(newFuzzySearchSelectItemsByItemId);
        setVisibleItemKeys( items.map(item => item.id) );


    }, [items])

    useEffect(() => {
        if (!fuzzySearchSelectItemsByItemId) {
            return;
        }

        onChange(name, selectedItem?.value);
        setIsOpen(false);
    }, [selectedItem])

    useEffect(() => {
        if (value === selectedItem?.value) {
            return;
        }

        // selectItem(value?.id);
        selectItem(value?.id);
    }, [value])

    useEffect(() => {
        // console.log('hoveredItemKey =', hoveredItemKey);
        setHoveredItemIndex( visibleItemIndexesByItemKey?.[hoveredItemKey] );
    }, [hoveredItemKey])

    const hoverPrevItem = () => {
        if (!visibleItemKeys.length) {
            setHoveredItemKey(null);
            return;
        }

        if ( !hoveredItemKey ) {
            setHoveredItemKey( visibleItemKeys[ visibleItemKeys.length - 1 ] );
            return;
        }

        const prevHoveredItemIndex = hoveredItemIndex - 1;

        if (prevHoveredItemIndex < 0) {
            setHoveredItemKey( visibleItemKeys[ visibleItemKeys.length - 1 ] );
            return;
        }

        setHoveredItemKey( visibleItemKeys?.[prevHoveredItemIndex] );
    }

    const hoverNextItem = () => {
        if (!visibleItemKeys.length) {
            setHoveredItemKey(null);
            return;
        }

        if ( !hoveredItemKey ) {
            setHoveredItemKey( visibleItemKeys[0] );
            return;
        }

        const nextHoveredItemIndex = hoveredItemIndex + 1;

        if (nextHoveredItemIndex >= visibleItemKeys.length) {
            setHoveredItemKey( visibleItemKeys[0] );
            return;
        }

        setHoveredItemKey( visibleItemKeys?.[nextHoveredItemIndex] );
    }

    const keyDownHandler = (e) => {
        if (!isOpen) {
            return;
        }

        let keyHandled = false;

        switch(true) {
            case( (e.key === 'Esc' || e.key === 'Escape') && canCloseViaEscKey ):
                setIsOpen(false);
                keyHandled = true;
            break;
            case(e.key === 'Enter'):
                selectHoveredItem();
                keyHandled = true;
            break;
            case(e.key === 'ArrowUp'):
                hoverPrevItem();
                keyHandled = true;
            break;
            case(e.key === 'ArrowDown'):
                hoverNextItem();
                keyHandled = true;
            break;
            default:
            break;
        }

        if (keyHandled) {
            e.preventDefault();
            return false;
        }
    }

    const addChoiceFormSuccessCallback = (response) => {
        if (!response?.id) {
            return;
        }

        setItemKeyToSelectAfterNextItemsUpdate(response.id);
    }

    return (
        <FormField label={label} required={required} id={id} errorMessage={errorMessage} {...otherProps}>
            <StyledFuzzySearchSelect ref={fuzzySearchSelectRef} $open={isOpen}>
                <StyledToggle $open={isOpen} onClick={toggle} $hasError={hasError}>
                    {iconLeft && (
                        <StyledIconLeftContainer>
                            {iconLeft}
                        </StyledIconLeftContainer>
                    )}
                    <StyledSelectedItemContainer>
                        <StyledSelectedItemText>
                            {selectedItem?.label || placeholder}
                        </StyledSelectedItemText>
                    </StyledSelectedItemContainer>
                    <StyledStateIndicator $open={isOpen}>
                        <FontAwesomeIcon icon={icon({name: 'angle-down', style: 'solid'})} />
                    </StyledStateIndicator>
                </StyledToggle>

                <StyledExpandable $open={isOpen}>
                    <StyledExpandableTop>
                        <StyledSearchInputContainer>
                            <FuzzySearchInput
                                iconLeft={icon({name: 'search', style: 'regular'})}
                                iconRight={icon({name: 'times', style: 'regular'})}
                                ref={fuzzySearchInputRef}
                                onQueryChange={doFuzzySearch}
                                onKeyDown={keyDownHandler}
                            />
                        </StyledSearchInputContainer>

                        {addChoiceForm && (
                            <StyledAddChoiceButtonWrapper style={{marginLeft: '10px'}}>
                                <Button
                                    iconLeft={<FontAwesomeIcon icon={icon({name: 'plus', style: 'solid'})} />}
                                    onClick={toggleAddChoiceModal}
                                >
                                    {addChoiceButtonLabel}
                                </Button>
                                <Modal isShowing={addChoiceModalIsShowing} hide={toggleAddChoiceModal} title={addChoiceButtonLabel}>
                                    <AddChoiceFormComponent successCallback={addChoiceFormSuccessCallback} hideModal={toggleAddChoiceModal} />
                                </Modal>
                            </StyledAddChoiceButtonWrapper>
                        )}
                    </StyledExpandableTop>
                    {/* {addText && (
                    <StyledAddButton onClick={toggleModal}>
                        <FontAwesomeIcon icon={icon({name: 'plus', style: 'light'})} />{addText}
                        <Modal isShowing={isShowing} hide={toggleModal} title={addText}>
                            <AddClientForm />
                        </Modal>
                    </StyledAddButton>
                    )} */}

                    <StyledItemsContainer>
                        {!!visibleItemKeys?.length && !!fuzzySearchSelectItemsByItemId && visibleItemKeys.map( (itemKey, i) => {
                            const item = fuzzySearchSelectItemsByItemId?.[itemKey];

                            if (!item) {
                                return;
                            }

                            return (
                                <FuzzySearchSelectItem
                                    key={item.id}
                                    itemKey={item.id}
                                    label={item.label}
                                    value={item.value}
                                    hovered={hoveredItemKey === item.id}
                                    highlightMatchIndices={fuzzySearchMatchIndicesByItemId?.[item.id]}
                                    onHoverHandler={hoverItem}
                                    onSelectHandler={selectItem}
                                />
                            );
                        } )}
                    </StyledItemsContainer>


                    {/* {addChoiceForm && (
                        <StyledAddChoiceButtonWrapper>
                            <Button
                                iconLeft={<FontAwesomeIcon icon={icon({name: 'plus', style: 'solid'})} />}
                                onClick={toggleAddChoiceModal}
                                size="sm"
                            >
                                {addChoiceButtonLabel}
                            </Button>
                            <Modal isShowing={addChoiceModalIsShowing} hide={toggleAddChoiceModal} title={addChoiceButtonLabel}>
                                <AddChoiceFormComponent successCallback={addChoiceFormSuccessCallback} />
                            </Modal>
                        </StyledAddChoiceButtonWrapper>
                    )} */}
                </StyledExpandable>
            </StyledFuzzySearchSelect>
        </FormField>
    );
}

export default FuzzySearchSelect;
