import React, {createContext, useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import get from 'lodash.get';

import {
    StyledArrayFieldEntry,
    StyledArrayFieldEntryFields,
    StyledArrayFieldEntryTitle,
    StyledArrayFieldEntryTitleBar,
    StyledArrayFieldEntryTitleBarCloseTrigger
} from "./ArrayFieldEntry.styles";

import {FormContext} from "./Form";

export const ArrayFieldEntryContext = createContext();

const ArrayFieldEntry = ({
    index = 0,
    arrayFieldName,
    entryComponent = null,
    labelSingular = 'Entry',
    ...otherProps
}) => {
    const formContext = useContext(FormContext);
    const [name, setName] = useState(`${arrayFieldName}.${index}`);

    useEffect(() => {
        if (arrayFieldName) {
            setName(`${arrayFieldName}.${index}`);
        }
    }, [arrayFieldName, index]);

    const remove = () => {
        let newValues = {...formContext.values};
        get(newValues, arrayFieldName)?.splice(index, 1);
        formContext.setValues(newValues);
    }

    return (
        <ArrayFieldEntryContext.Provider value={{arrayFieldName:arrayFieldName, index:index, name:name, values:get(formContext.values, name)}}>
            <StyledArrayFieldEntry {...otherProps}>
                <StyledArrayFieldEntryTitleBar>
                    <StyledArrayFieldEntryTitle>
                        {labelSingular} #{index+1}
                    </StyledArrayFieldEntryTitle>
                    <StyledArrayFieldEntryTitleBarCloseTrigger onClick={remove}>
                        <FontAwesomeIcon icon={icon({name: 'times', style: 'regular'})} />
                    </StyledArrayFieldEntryTitleBarCloseTrigger>
                </StyledArrayFieldEntryTitleBar>

                <StyledArrayFieldEntryFields>
                    {entryComponent}
                </StyledArrayFieldEntryFields>
            </StyledArrayFieldEntry>
        </ArrayFieldEntryContext.Provider>
    );
};

export default ArrayFieldEntry;
