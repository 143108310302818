import React from "react";
import {forwardRef, useContext, useEffect, useState} from "react";
import {StyledCheckboxField} from "./CheckboxField.styles";
import {StyledCheckboxFormLabel, StyledFormField} from "./FormField.styles";
import {FormContext} from "./Form";
import get from "lodash.get";

const CheckboxField = forwardRef( ({
    label = null,
    type = 'checkbox',
    required = false,
    name = null,
    value,
    onChange = null,
    id = null,
    hasError = false,
    checked = null,
    placeholder,
    isFormField = true,
    iconLeft,
    iconRight,
    ...otherProps
}, ref) => {
    const formContext = useContext(FormContext);
    const [adjustedName, setAdjustedName] = useState(name);
    const [adjustedId, setAdjustedId] = useState(id);

    const inputChangeHandler = (e) => {
        formContext.inputChangeHandler(adjustedName, e.target.checked);
    }

    useEffect(() => {
        if (!isFormField) return;

        if (!id && adjustedName) {
            setAdjustedId(adjustedName);
        }
    }, [adjustedName]);

    if (isFormField) {
        if (onChange === undefined || onChange === null) {
            onChange = inputChangeHandler;
        }

        if (checked === null) {
            checked = !!get(formContext.values, adjustedName, false);
        }
    }

    if (iconLeft) {
        iconLeft = React.cloneElement(iconLeft, {
            ...iconLeft.props,
            ...{
                className: `${iconLeft?.props?.className || ''} icon-left`
            },
        });
    }

    if (iconRight) {
        iconRight = React.cloneElement(iconRight, {
            ...iconRight.props,
            ...{
                className: `${iconRight?.props?.className || ''} icon-right`
            },
        });
    }

    return (
        <StyledFormField {...otherProps}>
            {label && (
                <StyledCheckboxFormLabel title={required ? `This field is required.` : undefined} htmlFor={adjustedId}>
                        <StyledCheckboxField
                            type={type}
                            id={adjustedId}
                            name={adjustedName}
                            hasError={hasError}
                            required={required}
                            checked={checked}
                            placeholder={placeholder}
                            ref={ref}
                            value={value}
                            onChange={onChange}
                        />
                        <div className="filter-row">
                            {iconLeft}
                            {iconLeft && (
                                <div>&nbsp;</div>
                            )}
                            {label}
                            {iconRight}
                            {required && ` *`}
                        </div>
                </StyledCheckboxFormLabel>
            )} 
        </StyledFormField>
    );
} );

/**
 * required for components/Form/Form.js to auto-apply the `value` and `onChange` props
 * (because child.type.name doesn't exist for forwardRef components)
 */
CheckboxField.name = 'CheckboxField';

export default CheckboxField;
