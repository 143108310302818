import Container from 'components/Grid/Container';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getColorFromSlug } from 'utility/colors';
import { screenWidth } from 'utility/screen-width';

export const header_height = 70;

export const StyledHeader = styled.header`
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: ${header_height}px;
    background: ${getColorFromSlug('david-yellow')};
    z-index:1050;
`

export const HeaderContainer = styled(Container)`
    display:flex;
    height: 100%;

    @media ${screenWidth.below.sm} {
        justify-content: space-between;
        width: 100%;
        padding: 0;
    }
`

export const MobileMenuToggle = styled.div`
    @media ${screenWidth.above.sm} {
        display: none;
    }

    @media ${screenWidth.below.sm} {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${header_height}px;
        height: 100%;
        font-size: 32px;
        color: ${getColorFromSlug('david-black')};
        cursor: pointer;
        transition: width 0s, font-size 0s;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.4);
            opacity: 0;
            transition: opacity 0.15s;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:active {
            >svg {
                transform: scale(0.8);
            }
        }
    }
`;

// export const LogoWrapper = styled.div`
// `;

export const LogoLink = styled( (props) => <Link {...props} /> )`
    display:block;
    height: 100%;
    padding: 10px;

    @media ${screenWidth.below.sm} {
        max-width: 100%;
    }

    svg {
        width: 100%;
        height: 100%;

        .kemper-web-team-logo-0 {
            fill: #fff;
        }
    }
`;

export const NavWrapper = styled.div`
    @media ${screenWidth.above.sm} {
        flex-grow: 1;
        display: flex;
        justify-content: end;
    }

    @media ${screenWidth.below.sm} {
        flex-shrink: 0;
        width: ${header_height}px;
        transition: width 0s;
    }
`;

export const MobileMenuOverlay = styled.div`
    @media ${screenWidth.above.sm} {
        display: none;
    }

    @media ${screenWidth.below.sm} {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        visibility: hidden;
        opacity: 0;
        z-index: 10;
        transition: visibility 0.15s, opacity 0.15s;

        ${({ open }) => open && `
            visibility: visible;
            opacity: 1;
        `}
    }
`;

export const HeaderBuffer = styled.div`
    height:${header_height}px;
`;
