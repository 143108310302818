import styled from 'styled-components';
import { getColor } from 'utility/colors';

export const StyledPageMessage = styled.div`
    display: flex;
    padding: 7px 15px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ $borderColor }) => getColor($borderColor)};
    background: ${({ $bgColor }) => getColor($bgColor)};
`;

export const StyledIconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 20px;
    font-size: 16px;
    margin-right: 15px;
    color: ${({ $iconColor }) => getColor($iconColor)};
`;

export const StyledMessageWrapper = styled.div`
    flex-grow: 1;
    color: ${({ $textColor }) => getColor($textColor)};
`;