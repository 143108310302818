import styled, { css } from 'styled-components';
import { getColorFromSlug } from 'utility/colors';

export const INPUT_HEIGHT = 55;

export const INPUT_FIELD_CSS = css`
    display: block;
    width: 100%;
    height: ${INPUT_HEIGHT}px;
    background: #fff;
    border: 1px solid black;
    font-size: 18px;
    outline: none;
    padding: 15px;
    margin: 0;

    &:focus {
        box-shadow: 0 0 4px rgba(0,0,0,0.3);
    }

    ${({ $hasError }) => $hasError && `
        border: 1px solid ${getColorFromSlug('error-border')};
    `}

    &[disabled],
    &[readonly] {
        background:#e0e0e0;
        opacity: 0.6;
        cursor:not-allowed;
    }
`;

export const StyledInput = styled.input`
    ${INPUT_FIELD_CSS}
`;