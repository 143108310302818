import React, {useContext, useEffect, useState} from 'react';
import {StyledCutLegend} from './FormComponents/Form.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import Autocomplete from "react-google-autocomplete";
import CutMapView from "../Layout/MapView/CutMapView";
import {convertAddressComponentsToNamedProperties} from 'utility/googleMaps';
import FormField from './FormComponents/FormField';
import SelectField from './FormComponents/SelectField';
import TextareaField from './FormComponents/TextareaField';
import CheckboxField from './FormComponents/CheckboxField';
import MeasurementForm from "./MeasurementForm";
import TextField from "./FormComponents/TextField";
import CutStepForm from "./CutStepForm";
import {FormContext} from "./FormComponents/Form";
import {ArrayFieldEntryContext} from "./FormComponents/ArrayFieldEntry";
import ArrayField from "./FormComponents/ArrayField";
import SpecialForm from "./SpecialForm";

function CutUpdatedForm({cutInitialState}) {
    const api = useDavidApi();
    const formContext = useContext(FormContext);
    const cutFieldEntryContext = useContext(ArrayFieldEntryContext);
    const [cut, setCut] = useState(cutInitialState);
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [productsLoading, setProductsLoading] = useState(true);
    const [productChoices, setProductChoices] = useState({});
    const [addressSelected, setAddressSelected] = useState({});
    const [convertedPlaceProperties, setConvertedPlaceProperties] = useState({});

    let coordinates = [cut.latitude, cut.longitude];

    useEffect(() => {
        if (cutFieldEntryContext.values !== undefined) {
            setCut(cutFieldEntryContext.values);
        }
    }, [cutFieldEntryContext.values]);

    useEffect(() => {
        if (api.products !== null) {
            setProducts(api.products);
            setProductsLoading(false);
            return;
        }

        (async () => {
            const response = await api.getProducts();
            setProducts(response);
            setProductsLoading(false);
        })();
    }, [api.products]);

    useEffect(() => {
        if (addressSelected && Object.keys(addressSelected).length !== 0) {
            setConvertedPlaceProperties( convertAddressComponentsToNamedProperties(addressSelected) );
            setGeolocation(addressSelected?.geometry?.location?.lat(), addressSelected?.geometry?.location?.lng());
        }
    }, [addressSelected]);

    useEffect(() => {
        if (cutFieldEntryContext.name !== null) {
            formContext.inputChangeHandler(cutFieldEntryContext.name + '.address', convertedPlaceProperties?.street_number?.long_name || '');
            formContext.inputChangeHandler(cutFieldEntryContext.name + '.street', convertedPlaceProperties?.route?.long_name || '');
            formContext.inputChangeHandler(cutFieldEntryContext.name + '.city', convertedPlaceProperties?.locality?.long_name || '');
            formContext.inputChangeHandler(cutFieldEntryContext.name + '.state', convertedPlaceProperties?.administrative_area_level_1?.short_name || '');
            formContext.inputChangeHandler(cutFieldEntryContext.name + '.zipcode', convertedPlaceProperties?.postal_code?.long_name || '');
        }
    }, [convertedPlaceProperties]);

    const setGeolocation = (lat, lng) => {
        formContext.inputChangeHandler(cutFieldEntryContext.name + '.latitude', lat);
        formContext.inputChangeHandler(cutFieldEntryContext.name + '.longitude', lng);
        coordinates = [lat, lng];
    }

    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    const cutSteps = [
        {label: 'Digout', value: {label: 'Digout', completedAt: currentDate}},
        {label: 'Base', value: {label: 'Base', completedAt: currentDate}},
        {label: 'Concrete', value: {label: 'Concrete', completedAt: currentDate}},
        {label: 'Surface', value: {label: 'Surface', completedAt: currentDate}}
    ]

    useEffect(() => {
        let product = cut.product;
        if (typeof cut.product === "string" && cut.product !== "") {
            product = JSON.parse(cut.product)
        }

        setProduct(product?.id);
    }, [cut.product]);

    useEffect(() => {
        if (productsLoading || !products?.length) {
            return;
        }

        setProductChoices( products.map( product => ({
            label: product.name,
            value: {id: product.id}
        }) ) );
    }, [products]);

    return (
        <Row gutter={15}>
            <Column width={1/2}>
                <StyledCutLegend>
                    <SelectField
                        label="Product"
                        name="product"
                        placeholder="Select One..."
                        value={{id: cut?.product?.id}}
                        choices={productChoices}
                        afterChangeEvent={ (e) => {
                            const product = JSON.parse(e.target.value)
                            formContext.inputChangeHandler(cutFieldEntryContext.name + '.product', product)
                        }}
                    />

                    {product === 4 &&
                        <ArrayField
                            name="specials"
                            label="Add Additional Work"
                            entryLabelSingular="Additional Work"
                            entryComponent={<SpecialForm />}
                        />
                    }

                    {product === 3 && (
                        <CheckboxField
                            label="Mono Curb"
                            name={cutFieldEntryContext.name + '.monoCurb'}
                        />
                    )}

                    {[1].includes(product) &&
                        <MeasurementForm fieldName={cutFieldEntryContext.name} cut={cut} product={1} />
                    }

                    {[2, 3].includes(product) &&
                        <MeasurementForm fieldName={cutFieldEntryContext.name} cut={cut} />
                    }

                    {[1, 2, 3].includes(product) &&
                        <div>
                            <h3 style={{marginTop:"15px", marginBottom:'15px'}}>Please mark completed steps:</h3>
                            {cutSteps.map((cutStep, index) => {
                                return (
                                    <CutStepForm cutStep={cutStep} fieldName={cutFieldEntryContext.name} cut={cutFieldEntryContext.values} key={index} />
                                );
                            })}
                        </div>
                    }

                    <FormField label="Search Address">
                        <Autocomplete
                            className="auto-complete-input"
                            apiKey={'AIzaSyBsm2iKNeKYNsoCOxXVy0oF0qOoZCuW6lQ'}
                            onPlaceSelected={(place) => { setAddressSelected(place); }}
                            options={{
                                types: [],
                                componentRestrictions: { country: "us" },
                            }}
                            placeholder="Type here to search..."
                        />
                    </FormField>
                    <TextField
                        label="Address"
                        name="address"
                    />
                    <TextField
                        label="Street"
                        name="street"
                    />
                    <Row gutter={15}>
                        <Column width={1/3}>
                            <TextField
                                label="City"
                                name="city"
                            />
                        </Column>
                        <Column width={1/3}>
                            <TextField
                                label="State"
                                name="state"
                            />
                        </Column>
                        <Column width={1/3}>
                            <TextField
                                label="Zip Code"
                                name="zipcode"
                            />
                        </Column>
                    </Row>

                    <TextareaField
                        label="Comments"
                        name="comments"
                    />

                </StyledCutLegend>

            </Column>
            <Column width={1/2}>
                <CutMapView
                    coordinates={coordinates}
                    cutMarkerDragendHandler={({lat, lng}) => setGeolocation(lat, lng)}
                    id={cut.id}
                    address={cut.address}
                    street={cut.street}
                    city={cut.city}
                    state={cut.state}
                    zipcode={cut.zipcode}
                />
            </Column>
        </Row>
    )
  }

export default CutUpdatedForm;
