import { StyledContainer } from "./Container.styles";

const Container = ({
    vpadding = 0,
    fluid = false,
    children,
    ...otherProps
}) => (
    <StyledContainer fluid={fluid} vpadding={vpadding} {...otherProps}>
        {children}
    </StyledContainer>
);

export default Container;
