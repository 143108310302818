import AbstractInputField from "./AbstractInputField";
import {useEffect, useState} from "react";

const DateField = ({
    value,
    ...otherProps
}) => {
    const [convertedValue, setConvertedValue] = useState('');

    useEffect(() => {
        if (value !== '' && value !== undefined) {
            setConvertedValue((new Date(value).toISOString().split('T')[0]));
        }
    }, [value]);

    return (
        <AbstractInputField type="date" value={convertedValue} {...otherProps} />
    );
}

export default DateField;
