import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

const digoutIcon = <FontAwesomeIcon icon={solid('person-digging')} />;
const baseIcon = <FontAwesomeIcon icon={solid('mound')} />;
const surfaceIcon =  <FontAwesomeIcon icon={solid('truck')} />;

export const cutStepLabels = [
    {
        name: "Digout",
        icon: digoutIcon,
    },
    {
        name: "Base",
        icon: baseIcon,
    },
    {
        name: "Surface",
        icon: surfaceIcon,
    },
]