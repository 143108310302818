import styled from 'styled-components';
// import { getColorFromSlug } from 'utility/colors';

export const StyledFuzzySearchSelectItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;

    ${({ hovered }) => hovered && `
        background: #ddd;
    `}
`;

