import styled from 'styled-components';
import { StyledInputAddon } from './InputWithAddon.styles';

export const StyledClearInput = styled.div`
    ${StyledInputAddon}:last-child {
        &:hover {
            >svg {
                color:#000;
            }
        }
    }
`;