import styled from 'styled-components';
import { getColorFromSlug } from 'utility/colors';

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    z-index: 1100;  
`

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .6; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${getColorFromSlug('david-yellow')};

    ${({ $isLighter }) => $isLighter && `
        opacity: .4;
    `}
`