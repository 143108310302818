import {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import get from 'lodash.get';
import set from 'lodash.set';

import {FormContext} from "./Form";
import ArrayFieldEntry, {ArrayFieldEntryContext} from "./ArrayFieldEntry";
import FormFieldGroup from "./FormFieldGroup";
import {ArrayFieldEntries} from "./ArrayField.styles";
import Button from "components/Button/Button";
import { YellowButtonWrapper } from "components/Button/Button.styles";

const ArrayField = ({
    name = null,
    label = null,
    required = false,
    entryComponent = null,
    entryLabelSingular = 'Entry',
    prototypeValue = {},
    ...otherProps
}) => {
    const formContext = useContext(FormContext);
    const arrayFieldEntryContext = useContext(ArrayFieldEntryContext);
    const [adjustedName, setAdjustedName] = useState(name);

    useEffect(() => {
        if (arrayFieldEntryContext?.name) {
            setAdjustedName(`${arrayFieldEntryContext.name}.${name}`);
        }
    }, [arrayFieldEntryContext]);

    const addEntry = () => {
        let newValues = {...formContext.values};

        if ( !get(newValues, adjustedName) ) {
            set(newValues, adjustedName, []);
        }

        let initialState = prototypeValue;

        if (typeof prototypeValue === 'function') {
            initialState = prototypeValue();
        }

        get(newValues, adjustedName).push(initialState);
        formContext.setValues(newValues);
    }

    return (
        <FormFieldGroup label={label} required={required} {...otherProps}>
            <ArrayFieldEntries>
                {!!get(formContext.values, adjustedName)?.length && get(formContext.values, adjustedName).map( (value, i) => (
                    <ArrayFieldEntry
                        key={i}
                        arrayFieldName={adjustedName}
                        index={i}
                        entryComponent={entryComponent}
                        labelSingular={entryLabelSingular}
                    />
                ) )}
            </ArrayFieldEntries>
            <YellowButtonWrapper>       
                <Button 
                    onClick={addEntry}
                    iconLeft={<FontAwesomeIcon icon={solid('plus')} />}
                >
                    Add {entryLabelSingular}
                </Button>
            </YellowButtonWrapper>
        </FormFieldGroup>
    );
};

export default ArrayField;
