import styled from 'styled-components';

import { breakpoints, screenWidth } from 'utility/screen-width';

export const StyledContainer = styled.div`
    margin: 0 auto;
    max-width: 100%;
    height: 100%;

    padding:${({ vpadding }) => vpadding}px 30px;

    ${({ fluid }) => !fluid && `
        @media ${screenWidth.above.xl} {
            width: ${breakpoints.xl}px;
        }

        @media ${screenWidth.is.lg} {
            width: ${breakpoints.lg}px;
        }

        @media ${screenWidth.is.md} {
            width: ${breakpoints.md}px;
        }

        @media ${screenWidth.is.sm} {
            width: ${breakpoints.sm}px;
        }
    `}
`;
