import styled from 'styled-components';

export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom:-${({ gutter }) => gutter}px;
    margin-right:-${({ gutter }) => gutter}px;

    ${({ centered }) => centered && `
        justify-content: center;
    `}
`;
