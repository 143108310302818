import React, {useEffect, useRef, useState} from 'react';
import Form from 'components/Form/FormComponents/Form';
import TextField from 'components/Form/FormComponents/TextField'
import SelectField from 'components/Form/FormComponents/SelectField';
import FuzzySearchSelect from 'components/FuzzySearch/FuzzySearchSelect';
import Button from 'components/Button/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {RedButtonWrapper} from 'components/Button/Button.styles';
import {
    SidebarCloseButton,
    SidebarHeader,
    SidebarHeaderWrapper,
    SidebarOverlay,
    SidebarWrapper,
    StyledSidebar
} from './Sidebar.styles';
import CutInfoContainer from './CutInfoContainer';
import Column from "../../../Grid/Column";
import Row from "../../../Grid/Row";
import {useDavidApi} from "../../../../utility/DavidApi/DavidApi";

function MapSidebar({
    initialState,
    handleFilterSubmit,
    cuts,
    inputs,
    setInputs,
    hide,
    isOpen,
    setLoading,
    refreshCuts,
    setSidebarFilterSubmitted
 }) {
    const api = useDavidApi();

    const [productChoices, setProductChoices] = useState(null);
    
    // const [clientFuzzySearchSelectItems, setClientFuzzySearchSelectItems] = useState({});

    const submitHandler = (e) => {
        e.preventDefault();
        handleFilterSubmit(e);
        scrollToFilteredResults();
        setSidebarFilterSubmitted(true);
    }

    const resetHandler = (e) => {
        e.preventDefault();
        setInputs(initialState);
        scrollToClearedForm();
        handleFilterSubmit(e, true);
        setSidebarFilterSubmitted(false);
    }

    // useEffect(() => {
    //     const clients = api.clients;
    //     if (!clients?.length) {
    //         return;
    //     }

    //     setClientFuzzySearchSelectItems(clients.map( client => ({
    //         label: client.name,
    //         value: client.id,
    //         id: client.id,
    //     }) ) );
    // }, [api.clients]);

    useEffect(() => {
        if (api.clients !== null) {
            return;
        }

        (async () => {
            await api.getClients();
        })();
    }, [api.clients]);

    useEffect(() => {
        const products = api.products;
        if (!products?.length) {
            return;
        }

        setProductChoices(products.map( product => ({
            label: product.name,
            value: product.id,
        }) ) );
    }, [api.products]);

    const filteredResults = useRef();

    const scrollToFilteredResults = () => {
        filteredResults.current.scrollIntoView();
    }

    const clearedForm = useRef();

    const scrollToClearedForm = () => {
        clearedForm.current.scrollIntoView();
    }


    return (
        <div>
            <SidebarOverlay />
            <SidebarWrapper>
                <StyledSidebar isOpen={isOpen} >
                    <div ref={clearedForm}></div>
                    <SidebarHeaderWrapper>
                        <SidebarHeader>
                            <h3>Filter Results</h3>
                            <SidebarCloseButton onClick={hide}>
                                <span aria-hidden="true">&times;</span>
                            </SidebarCloseButton>
                        </SidebarHeader>
                    </SidebarHeaderWrapper>
                    <Form values={inputs} setValues={setInputs} onSubmit={submitHandler} style={{marginTop:'40px'}}>
                        <FuzzySearchSelect
                            label="Client"
                            name='["project.client"]'
                            placeholder="Select One..."
                            // items={clientFuzzySearchSelectItems}
                            items={api.clients.map( client => ({
                                label: client.name,
                                value: client.id,
                                id: client.id,
                            }) ) }
                        />
                        <SelectField
                            label="Product"
                            name='["product.id"]'
                            placeholder="Select One..."
                            choices={productChoices}
                        />
                            <SelectField
                                label="Cut Step"
                                name="step"
                                placeholder="Select One..."
                                choices={[
                                    'Digout',
                                    'Base',
                                    'Surface',
                                ]}
                            />
                            <TextField
                                label="Project Number"
                                name='["project.projectNumber"]'
                            />
                            <TextField
                                label="Excavation Number"
                                name='["project.excavationNumber"]'
                            />
                            <TextField
                                label="Address"
                                name="address"
                            />
                            <TextField
                                label="Street"
                                name="street"
                            />
                            <Row>
                                <Column width={1/4}>
                                    <Button
                                        style={{marginBottom:'20px', marginTop:'10px'}}
                                        onClick={submitHandler}
                                        iconLeft={<FontAwesomeIcon icon={solid('check')} />}
                                        size="sm"
                                        >
                                            Filter
                                    </Button>
                                </Column>
                                <Column width={1/4}></Column>
                                <Column width={1/4}></Column>
                                <Column width={1/4}>
                                    <RedButtonWrapper>
                                        <Button
                                            style={{marginBottom:'20px', marginTop:'10px'}}
                                            onClick={(e) => {resetHandler(e)}}
                                            iconLeft={<FontAwesomeIcon icon={solid('x')} />}
                                            size="sm"
                                            >
                                                Reset
                                        </Button>
                                    </RedButtonWrapper>
                                </Column>
                            </Row>
                        </Form>
                        <h4 ref={filteredResults} style={{paddingTop: '10px'}}>Cut List</h4>
                        <hr />
                    {cuts && cuts?.length >= 1 && cuts.map(cut => (
                        <CutInfoContainer
                            cut={cut}
                            key={cut.id}
                            refreshCuts={refreshCuts}
                            setLoading={setLoading}
                        />
                    ))}
                    {!cuts.length &&
                        <p>There are no cuts that match your search.</p>
                    }
                </StyledSidebar>
            </SidebarWrapper>
        </div>
    )
}

export default MapSidebar;
