import React from "react";

import Column from "components/Grid/Column";
import { StyledRow } from "./Row.styles";

const Row = ({
    gutter = 0,
    centered = false,
    children,
    ...otherProps
}) => {
    return (
        <StyledRow gutter={gutter} centered={centered} {...otherProps}>
            {React.Children.map(children, child => {
                if ( child && child.type === Column ) {
                    return React.cloneElement(child, {gutter: gutter});
                }

                return child;
            })}
        </StyledRow>
    );
}

export default Row;