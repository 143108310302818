import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {StyledSubmenu, StyledSubmenuItem, StyledTopLevelMenuItem} from './NavMenuItem.styles';

const NavMenuItem = ({
    isSubmenuItem,
    textMobileOnly,
    iconMobileOnly,
    mobileOnly,
    to,
    icon,
    text,
    children,
    onClick
}) => {
    const [hovered, setHovered] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const hasSubmenu = !!children;


    const MenuItemTag = (isSubmenuItem ? StyledSubmenuItem : StyledTopLevelMenuItem);
    const LinkTag = to ? Link : 'div';
    const linkTagProps = to ? {to:to} : {};
    let IconEl = null;

    if (icon) {
        if (
            (Array.isArray(icon) || typeof icon === 'string')
            || (
                typeof icon === 'object'
                && !Array.isArray(icon)
                && icon !== null
            )
        ) {
            IconEl = ( <FontAwesomeIcon icon={icon} /> );
        } else {
            IconEl = icon;
        }
    }

    const onMouseEnterHandler = () => {
        setHovered(true);

        if (hasSubmenu) {
            setSubmenuOpen(true);
        }
    }

    const onMouseLeaveHandler = () => {
        setHovered(false);

        if (hasSubmenu) {
            setSubmenuOpen(false);
        }
    }

    const renderSubmenu = () => {
        if (!children) {
            return;
        }

        const submenuItems = React.Children.map(children, (child) => React.cloneElement(child, {
            isSubmenuItem: true,
        }));

        return (
            <StyledSubmenu className="submenu" open={submenuOpen}>
                {submenuItems}
            </StyledSubmenu>
        );
    }

    return (
        <MenuItemTag
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            hovered={hovered}
            textMobileOnly={textMobileOnly}
            iconMobileOnly={iconMobileOnly}
            mobileOnly={mobileOnly}
            onClick={onClick}
        >
            <LinkTag {...linkTagProps}>
                {IconEl}
                {(text &&
                    <span>{text}</span>
                )}
            </LinkTag>
            {renderSubmenu()}
        </MenuItemTag>
    );
}

export default NavMenuItem;
