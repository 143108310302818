import {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import SEO from 'components/SEO/SEO';
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import Container from 'components/Grid/Container';
import DataTable from "../components/Table/DataTable";
import TableModal from "../components/Table/TableModal";
import useModal from "../components/Modal/useModal";
import Button from "../components/Button/Button";
import ReviewCutForm from "../components/Form/ReviewCutForm";

const columns = [
    // {
    //     name: "id",
    //     label: "ID",
    //     options: {
    //         filter: true,
    //         sort: true,
    //     }
    // },
    {
        name: "client",
        label: "Client",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "address",
        label: "Address",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "street",
        label: "Street",
        options: {
            filter: true,
            sort: true,
        }
    },
    // {
    //     name: "city",
    //     label: "City",
    //     options: {
    //         filter: true,
    //         sort: true,
    //     }
    // },
    // {
    //     name: "state",
    //     label: "State",
    //     options: {
    //         filter: true,
    //         sort: true,
    //     }
    // },
    {
        name: "zipcode",
        label: "Zipcode",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "actual",
        label: "Actual",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "actions",
        label: "Actions",
        options: {
            filter: false,
            sort: false,
        }
    },
];

function Review() {
    const api = useDavidApi();
    const reviewCutsParameters = {
        exists: {
            reviewed: false
        },
        incomplete: false
    };
    const [loading, setLoading] = useState(true);
    const reviewCutModal = useModal();
    const [approveCutModal, setApproveCutModal] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.getCuts(reviewCutsParameters);
    }, []);

    useEffect(() => {
        if (api.cuts === null) {
            return;
        }
        setLoading(false);
        reviewCutModal.hide();
    }, [api.cuts]);

    const approveCutModalTriggerHandler = (cut) => {
        setApproveCutModal(cut);
        reviewCutModal.show();
    }

    const getTableRowDataForClient = (cut) => {
        let cutTableRowData = {
            ...cut,
            ... {
                client: cut?.project?.client?.name,
                actual: (cut?.actualLength === undefined ? 'No measurements provided' : cut?.actualLength + '"x' + cut?.actualWidth + '"x' + cut?.actualDepth + '"'),
                actions: (
                    <div className="table-row-actions-container">
                        <Button
                            size={'sm'}
                            style={{marginRight: '20px'}}
                            iconLeft={<FontAwesomeIcon icon={solid('edit')} />}
                            to={"/projects/" + cut?.project?.id + "/edit"}
                            target="_blank"
                        >
                            Edit
                        </Button>
                        <Button
                            size={'sm'}
                            iconLeft={<FontAwesomeIcon icon={solid('square-check')} />}
                            onClick={() => approveCutModalTriggerHandler(cut)}
                        >
                            Approve
                        </Button>
                    </div>
                )
            }
        };

        return cutTableRowData;
    }

        return(
            <>
                <SEO title="Review" />
                <Container vpadding={30}>
                    <h1>Review Cuts</h1>
                    <div>
                        <DataTable
                            columns={columns}
                            data={api?.cuts?.length && api?.cuts.map( (client) => getTableRowDataForClient(client) )}
                            loading={loading}
                            options={{
                                sortOrder: {
                                    name: 'name',
                                    direction: 'asc',
                                }
                            }}
                        />
                        <TableModal title="Approve Cut" isShowing={reviewCutModal.isShowing} hide={reviewCutModal.hide}>
                            <ReviewCutForm
                                cut={approveCutModal}
                                hideModal={reviewCutModal.hide}
                                reviewCutsParameters={reviewCutsParameters}
                            />
                        </TableModal>
                    </div>
                </Container>
            </>

    )
}

export default Review;
