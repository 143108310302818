import { css } from 'styled-components';

const ImageStyles = css`
    img, svg {
        width:auto;
        height:auto;
        max-width:100%;
        max-height:100%;

        /*
        * Hack to prevent img elements from overflowing their container in IE
        * (from https://stackoverflow.com/a/43227016)
        */
        flex: 0 0 auto;  /* IE */
        object-fit: scale-down; /* FF */
    }

    svg {
        fill: currentColor;
    }
`;

export default ImageStyles;
