import styled from 'styled-components';

import { getColorFromSlug } from 'utility/colors';
import { screenWidth } from 'utility/screen-width';

export const StyledNav = styled.nav`
    @media ${screenWidth.above.sm} {
        /* flex-grow: 1; */
        width: 100%;
    }

    @media ${screenWidth.below.sm} {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        max-width: 100%;
        height: 100%;
        background: ${getColorFromSlug('david-yellow')};

        z-index: 11;
        transition: transform 0.2s, box-shadow 0.2s;

        ${({ open }) =>
            open
            ? `
                transform: translateX(0);
                box-shadow: 0 0 11px rgba(0,0,0,0.5);
            `
            : `
                transform: translateX(-100%);
            `
        }
    }
`;

export const MobileMenuTop = styled.div`
    @media ${screenWidth.above.sm} {
        display: none;
    }

    @media ${screenWidth.below.sm} {
        flex-shrink: 0;
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.15);
    }
`;

export const MenuTitle = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    color: rgba(0,0,0,0.6);
    padding: 0 20px;
`;

export const CloseMobileMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    align-self: flex-end;
    width: 50px;
    height: 50px;
    color: #000;
    font-size: 24px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.4);
        opacity: 0;
        transition: opacity 0.15s;
    }

    >svg {
        opacity: 0.6;
        transform: scale(1);
        transition: opacity 0.15s, transform 0.15s;
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        >svg {
            opacity: 1;
            transform: scale(1.3);
        }
    }

    &:active {
        >svg {
            transform: scale(1);
            transition: transform 0s;
        }
    }
`;

export const MobileMenuBottom = styled.div`
    @media ${screenWidth.above.sm} {
        height: 100%;
    }

    @media ${screenWidth.below.sm} {
        flex-grow: 1;
    }
`;

export const MenuItemsWrapper = styled.div`
    @media ${screenWidth.above.sm} {
        display: flex;
        flex: 1 1 100%;
        justify-content: flex-end;
        height: 100%;
    }

    @media ${screenWidth.below.sm} {
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
`;