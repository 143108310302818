import React from "react";
import {forwardRef, useContext, useEffect, useState} from "react";
import {FormContext} from "./Form";
import { StyledRadioButton, StyledRadioButtonLabel } from "./RadioCheckbox.styles";
import { StyledFormField } from "./FormField.styles";
import get from "lodash.get";


const RadioCheckbox = forwardRef(({
    type = "radio",
    id = null,
    name = null,
    selected = null,
    onClick = null,
    required = false,
    label = null,
    value = null,
    iconLeft,
    iconRight,
    ...otherProps
}, ref) => {
    const formContext = useContext(FormContext);
    const [adjustedName, setAdjustedName] = useState(name);
    const [adjustedValue, setAdjustedValue] = useState(false);
    const [adjustedId, setAdjustedId] = useState(id);

    const inputChangeHandler = (e) => {
        // let formValue = e.target.value;
        const updatedValue = !adjustedValue;
        setAdjustedValue(updatedValue);
        formContext.inputChangeHandler(adjustedName, updatedValue);
    }

    if (value === false || value === undefined) {
        value = get(formContext.values, adjustedName, false);
        value = (value === 1);
    }

    if (onClick === undefined || onClick === null) {
        onClick = e => inputChangeHandler(e);
    }

    if (selected === null) {
        selected = adjustedValue;
    }

    if (iconLeft) {
        iconLeft = React.cloneElement(iconLeft, {
            ...iconLeft.props,
            ...{
                className: `${iconLeft?.props?.className || ''} icon-left`
            },
        });
    }

    if (iconRight) {
        iconRight = React.cloneElement(iconRight, {
            ...iconRight.props,
            ...{
                className: `${iconRight?.props?.className || ''} icon-right`
            },
        });
    }

    useEffect(() => {
        if (!id && adjustedName) {
            setAdjustedId(adjustedName);
        }
    }, [adjustedName]);

    useEffect(() => {
        setAdjustedValue(value);
    }, [value]);

    return (
        <StyledFormField {...otherProps}>
            {label && (
                <StyledRadioButtonLabel title={required ? `This field is required.` : undefined} htmlFor={adjustedId}>
                    <StyledRadioButton
                        id={adjustedId}
                        name={adjustedName}
                        value={value}
                        onClick={onClick}
                        ref={ref}
                        selected={selected}
                        required={required}
                    />
                    <div className="filter-row">
                        {iconLeft}
                        {iconLeft && (
                            <div style={{marginRight: '8px'}}></div>
                        )}
                        {label}
                        {iconRight}
                        {required && ` *`}
                    </div>
                </StyledRadioButtonLabel>
            )}
        </StyledFormField>
    );
});

/**
 * required for components/Form/Form.js to auto-apply the `value` and `onChange` props
 * (because child.type.name doesn't exist for forwardRef components)
 */
RadioCheckbox.name = 'RadioCheckbox';

export default RadioCheckbox;