import {useEffect, useState} from 'react'

import SEO from 'components/SEO/SEO';
import Container from 'components/Grid/Container';
import Form from 'components/Form/FormComponents/Form';
import { SubmitButton } from 'components/Button/Button.styles';
import ArrayField from 'components/Form/FormComponents/ArrayField';
import TextField from 'components/Form/FormComponents/TextField';
import NumberField from 'components/Form/FormComponents/NumberField';
import Testcomponent from 'components/Testcomponent';
import SelectField from 'components/Form/FormComponents/SelectField';
import TextareaField from 'components/Form/FormComponents/TextareaField';
import DateField from 'components/Form/FormComponents/DateField';
import FuzzySearchSelect from 'components/FuzzySearch/FuzzySearchSelect';
import AddClientForm from 'components/Form/AddClientForm';
import { useDavidApi } from 'utility/DavidApi/DavidApi';
import Autocomplete from "react-google-autocomplete";
import FormField from 'components/Form/FormComponents/FormField';


function Test3() {
    const api = useDavidApi();

    const [clientsLoading, setClientsLoading] = useState(false);
    const [myFormValues, setMyFormValues] = useState({});

    const submitHandler = async (values) => {
        // console.log(values);
        // return false;
    }

    // useEffect(() => {
    //     if (api.clients === null) {
    //         setClientsLoading(true);
    //         api.fetchClients();
    //         return;
    //     }

    //     setClientsLoading(false);
    // }, [api.clients]);

    useEffect(() => {
        api.getClients();
    }, []);

    // useEffect(() => {
    //     console.log('useEffect', DavidApi.clients);
    //     if (DavidApi.clients === null) {
    //         DavidApi.getClients();
    //         // (async () => {
    //         //     await DavidApi.getClients();
    //         //     setClients(response);
    //         //     setClientsLoading(false);
    //         // })();
    //     }
        

    //     setClients(DavidApi.clients);
    //     // if (DavidApi.clients !== null) {
    //     //     return;
    //     // }

    //     // (async () => {
    //     //     const response = await DavidApi.getClients();
    //     //     setClients(response);
    //     //     setClientsLoading(false);
    //     // })();
    // }, [DavidApi.clients]);

    return (
        <>
            <SEO title="Test" />
            <Container vpadding={30}>
                <h1>Test</h1>

                <Form values={myFormValues} setValues={setMyFormValues} asyncSubmitHandler={submitHandler}>
                    <FuzzySearchSelect
                        label="Client"
                        name="client"
                        placeholder="Select One..."
                        // items={ [...new Array(99)].map( (el, i) => ({label:`Client ${i}`, value:i, id:i}) ) }
                        items={!clientsLoading && !!api.clients?.length && api.clients.map( client => ({
                            label: client.name,
                            value: client.id,
                            id: client.id,
                        }) )}
                        addChoiceButtonLabel="Add Client"
                        addChoiceForm={AddClientForm}
                    />
                    <TextField
                        label="Text Field"
                        name="textfield"
                    />
                    <NumberField
                        label="Number Field"
                        name="numberfield"
                    />
                    <SelectField
                        label="Select Field"
                        name="selectfield"
                        placeholder="choose your fate"
                        choices={[
                            {
                                label: 'hello1',
                                value: {id:1,uuid:'asdfasdf'}
                            },
                            {
                                label: 'hello2',
                                value: 2
                            },
                            {
                                label: 'hello3',
                            },
                            'hello4'
                        ]}
                    />
                    <TextareaField
                        label="Textarea Field"
                        name="textareafield"
                        rows={6}
                    />
                    <DateField
                        label="Date Field"
                        name="datefield"
                    />

                    <ArrayField
                        name="mygroup"
                        label="My Group"
                        entryLabelSingular="Main Item"
                        entryComponent={
                            <>
                                <TextField
                                    label="Main Text Field"
                                    name="maintextfield"
                                />
                                <FormField label="Search Address">
                                    <Autocomplete
                                        className="auto-complete-input"
                                        apiKey={'AIzaSyBsm2iKNeKYNsoCOxXVy0oF0qOoZCuW6lQ'}
                                        // onPlaceSelected={(place) => { console.log(place); setAddressSelected(place); }}
                                        options={{
                                            types: [],
                                            componentRestrictions: { country: "us" },
                                        }}
                                        placeholder="Type here to search..."
                                    />
                                </FormField>
                                <ArrayField
                                    name="mysubgroup"
                                    label="My Group2"
                                    entryLabelSingular="Sub Item"
                                    entryComponent={
                                        <Testcomponent />
                                    }
                                />
                            </>
                        }
                    />

                    <SubmitButton>Submit</SubmitButton>
                </Form>
            </Container>
        </>
    )
}

export default Test3;
