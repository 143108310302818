import { StyledFormFieldGroup, StyledFormFieldGroupLabel } from "./FormFieldGroup.styles";

const FormFieldGroup = ({
    label = null,
    required = false,
    children,
    ...otherProps
}) => {
    return (
        <StyledFormFieldGroup>
            {label && (
                <StyledFormFieldGroupLabel title={required ? `These fields are required.` : undefined}>
                    {label}
                    {required && ` *`}
                </StyledFormFieldGroupLabel>
            )}
            
            {children}
        </StyledFormFieldGroup>
    );
};

export default FormFieldGroup;