import styled from 'styled-components';
import { screenWidth } from 'utility/screen-width';

export const StyledColumn = styled.div`
    margin-bottom:${({ gutter }) => gutter}px;
    padding-right:${({ gutter }) => gutter}px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;

    ${({ $width }) => $width && `
        @media ${screenWidth.above.sm} {
            flex: 0 0 ${$width * 100}%;
            width: ${$width * 100}%; /* For old syntax, otherwise collapses. */
            max-width: ${$width * 100}%; /* IE11 fix */
        }
    `}

    ${({ $widths }) => Object.keys($widths).map(breakpoint_label => `
        @media ${screenWidth.above[breakpoint_label]} {
            flex: 0 0 ${$widths[breakpoint_label] * 100}%;
            width: ${$widths[breakpoint_label] * 100}%; /* For old syntax, otherwise collapses. */
            max-width: ${$widths[breakpoint_label] * 100}%; /* IE11 fix */
        }
    `)}
`;
