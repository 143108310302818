import React from 'react';
import ReactDOM from 'react-dom';
import { StyledModal, ModalHeader, ModalOverlay, ModalWrapper, ModalCloseButton, ModalBody, ModalFooter } from './Modal.styles';

const Modal = ({ 
    isShowing,
    title = null,
    hide,
    children,
    modalRef,
}) => isShowing ? ReactDOM.createPortal(

    <React.Fragment>
        <ModalOverlay />
        <ModalWrapper>
            <StyledModal ref={modalRef}>
                <ModalHeader>
                    <h2>{title}</h2>
                    <ModalCloseButton onClick={hide}>
                        <span aria-hidden="true">&times;</span>
                    </ModalCloseButton>
                </ModalHeader>
                    <ModalBody>
                        {children}
                    </ModalBody>
                <ModalFooter />
            </StyledModal>
        </ModalWrapper>
    </React.Fragment>, document.body

) : null;

export default Modal;
