import React, { useContext, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import CheckboxField from "./FormComponents/CheckboxField";
import { FormContext } from "./FormComponents/Form";

function CutStepForm({ cutStep, fieldName, cut }) {
    const [isChecked, setIsChecked] = useState(false);
    const formContext = useContext(FormContext);

    useEffect(() => {
        if (!cut?.steps?.length) {
            setIsChecked(false);
            return;
        }

        for (const step of cut.steps) {
            if (step.label === cutStep.label) {
                setIsChecked(true);
                return;
            }
        }

        setIsChecked(false);
    }, [cut.steps])

    return (
        <CheckboxField
            name={`${fieldName}.steps.${cutStep.label}`}
            id={`${fieldName}.steps.${cutStep.label}`}
            label={`${cutStep.label} is completed`}
            checked={isChecked}
            onChange={(e) => {
                let steps = cloneDeep(get(formContext.values, `${fieldName}.steps`));
                if (e.target.checked) {
                    steps = [...steps, cutStep.value];
                } else {
                    steps = steps.filter((item) => {
                        return item.label !== cutStep.label;
                    });
                }

                formContext.inputChangeHandler(`${fieldName}.steps`, steps);
            }}
        />
    )
}

export default CutStepForm;
