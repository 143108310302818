import Container from "components/Grid/Container";
import SEO from "components/SEO/SEO";
// import { useMap } from 'react-leaflet';
import MapView from "../components/Layout/MapView/MapView";
import MapSidebar from "../components/Layout/MapView/Sidebar/MapSidebar";
import {useEffect, useState} from "react";
import useSidebar from "components/Layout/MapView/Sidebar/useSidebar";
import SidebarButton from "components/Layout/MapView/Sidebar/SidebarButton";
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import {AuthenticatedTemplate} from '@azure/msal-react';
import LoadingOverlay from "../components/Layout/MapView/LoadingOverlay/LoadingOverlay";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function Home() {
    const api = useDavidApi();

    const initialState = {
        incomplete: true
    };

    const [inputs, setInputs] = useState(initialState);
    const [cuts, setCuts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('Viewing All Cuts');
    const {isOpen, toggleSidebar} = useSidebar();
    const [centerCoordinates, setCenterCoordinates] = useState([37.977432, -87.544054]);
    const [newZoom, setNewZoom] = useState(12);
    const [subtitle, setSubtitle] = useState('');
    const [sidebarFilterSubmitted, setSidebarFilterSubmitted] = useState(false);

    useEffect(() => {
        refreshCuts();

        (async () => {
            await api.getClients();
            await api.getProducts();
        })();
    }, []);

    const handleFilterSubmit = (e = null, resetState = false) => {
        setLoading(true);

        if (e) {
            e.preventDefault();
        }

        let productType = 'All';
        if (inputs["product.id"] === '1'){
            productType = "Asphalt";
        } else if (inputs["product.id"] === '2'){
            productType = "Concrete";
        } else if (inputs["product.id"] === '3'){
            productType = "Concrete - Curb";
        } else if (inputs["product.id"] === '4') {
            productType = "Additional Work";
        }

        let step = '';
        if (inputs.step !== undefined) {
            step = 'on the ' + inputs.step + ' Step';
        }

        let forClientName = '';

        //handles error from CutLegendControl
        if (inputs["project.client"]) {
            if (inputs["project.client"] !== '' ) {
                const selectedClientId = inputs["project.client"];
                const selectedClient = api.clients.find(client => client.id === selectedClientId);
                if (selectedClient) {
                    forClientName = ' for ' + selectedClient.name; //sets client name in title if filtered in sidebar
                }
        }}

        setTitle('Viewing ' + productType + ' Cuts ' + step + forClientName);
        refreshCuts(resetState);

        if (resetState === true) {
            setTitle('Viewing All Cuts');
            setNewZoom(12);
        }
    }

    const refreshCuts = (resetState = false) => {
        (async () => {
            let response = [];

            if (resetState) {
                response = await api.searchAllCuts(initialState);
            } else {
                response = await api.searchAllCuts(inputs);
            }
            setCuts(response);

            if (response?.length === 0) {
                setSubtitle("There are no cuts that match your search.")
            } else {
                setSubtitle('');
            }

            setLoading(false);
        })();
    }

    useEffect(() => {
        if (cuts?.length) {
            const latitudesAndLongitudes = cuts.map(cut => ({
                latitude: cut.latitude,
                longitude: cut.longitude
            }));

            if (latitudesAndLongitudes?.length > 10) {
                setNewZoom(10);
            } else if (latitudesAndLongitudes?.length >= 5 && latitudesAndLongitudes?.length <= 10) {
                setNewZoom(12);
            } else if (latitudesAndLongitudes?.length > 0 && latitudesAndLongitudes?.length < 5) {
                setNewZoom(14);
            } else {
                setNewZoom(12);
            }

            const sumLatitudes = latitudesAndLongitudes.reduce((sum, point) => sum + point.latitude, 0);
            const sumLongitudes = latitudesAndLongitudes.reduce((sum, point) => sum + point.longitude, 0);
            const avgLatitude = sumLatitudes / latitudesAndLongitudes.length;
            const avgLongitude = sumLongitudes / latitudesAndLongitudes.length;
            const centerPoint = [avgLatitude, avgLongitude];

            setCenterCoordinates(centerPoint);
        } else if (cuts?.length === 0) {
            setNewZoom(12);
            setCenterCoordinates([37.977432, -87.544054]);
        }

    }, [cuts]);



    return (
        <>
            <AuthenticatedTemplate>
                <SEO title="Home" />
                {loading && (
                    <LoadingOverlay />
                )}
                <Container style={{ margin:"0 auto", height:'calc(100vh - 70px)', width:'100%', padding:"0", display: 'flex' }}>

                    <div className="leaflet-top leaflet-left">
                        <SidebarButton
                            className="leaflet-control"
                            onClick={toggleSidebar}
                            iconLeft={<FontAwesomeIcon icon={solid('bars-filter')} />}
                        >
                            Filter Results
                        </SidebarButton>
                    </div>

                    {isOpen &&
                        <MapSidebar
                            initialState={initialState}
                            handleFilterSubmit={handleFilterSubmit}
                            cuts={cuts}
                            inputs={inputs}
                            setInputs={setInputs}
                            setLoading={setLoading}
                            refreshCuts={refreshCuts}
                            hide={toggleSidebar}
                            setSidebarFilterSubmitted={setSidebarFilterSubmitted}
                        />
                    }

                    <MapView
                        cuts={cuts}
                        initialState={initialState}
                        refreshCuts={refreshCuts}
                        loading={loading}
                        setLoading={setLoading}
                        title={title}
                        subtitle={subtitle}
                        handleFilterSubmit={handleFilterSubmit}
                        inputs={inputs}
                        setInputs={setInputs}
                        centerCoordinates={centerCoordinates}
                        newZoom={newZoom}
                        sidebarFilterSubmitted={sidebarFilterSubmitted}
                        setSidebarFilterSubmitted={setSidebarFilterSubmitted}
                     />
                </Container>
            </AuthenticatedTemplate>
        </>
    );
}

export default Home;
