import React, {useRef} from 'react';

import {renderToStaticMarkup} from 'react-dom/server';
import {divIcon} from 'leaflet';
import {Marker, Popup, Tooltip} from 'react-leaflet';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import ExternalLink from "../../Link/ExternalLink";
import useModal from "../../Modal/useModal";
import EditCutForm from 'components/Form/EditCutForm';
import FlexWraper from 'components/Grid/FlexWrapper';

function MapMarker({
    coordinates,
    address,
    street,
    city,
    state,
    zipcode,
    date,
    weight,
    orderDate,
    cut,
    refreshCuts,
    setLoading
}) {
    const {isShowing, toggleModal} = useModal();

    //Icon colors
    let iconStyle = {color: '#2da322'};
    function isMoreThanNDaysAgo(date, days, lessThan = false) {
        const transformedDate = new Date(date);
        //                   days  hours min  sec  ms
        const thirtyDaysInMs = days * 24 * 60 * 60 * 1000;
        const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

        if (lessThan) {
            return timestampThirtyDaysAgo < transformedDate;
        }

        return timestampThirtyDaysAgo > transformedDate;
    }

    if (isMoreThanNDaysAgo(date, 7) && isMoreThanNDaysAgo(date, 30, true)) {
        iconStyle = {color: '#ffbc03'};
    } else if (isMoreThanNDaysAgo(date, 30)) {
        iconStyle = {color: '#ff4a26'};
    }

    //Custom icons depending on step
    let latestStep = '';
    let iconMarkup = '';

    if (cut.steps.length === 0){
        latestStep = "Digout";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'person-digging', style: 'solid'})} />);
    } else if (cut.steps.length === 1) {
        latestStep = "Base";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'mound', style: 'solid'})} />);
    } else if (cut.steps.length === 2) {
        latestStep = "Surface";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'truck', style: 'solid'})} />);
    } else {
        latestStep = "Complete";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'square-check', style: 'solid'})} />);
    }

    if (cut.product.name === "Concrete" && cut.steps.length > 0){
        latestStep = "Complete";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'square-check', style: 'solid'})} />);
    } else if (cut.product.name === "Concrete - Curb" && cut.steps.length > 0) {
        latestStep = "Complete";
        iconMarkup = renderToStaticMarkup(<FontAwesomeIcon style={iconStyle} icon={icon({name: 'square-check', style: 'solid'})} />);
    }

    const customMarkerIcon = divIcon({
            html: iconMarkup,
            iconSize: [35, 35],
    });
    
    const formattedDate = new Date(orderDate).toLocaleDateString('en-us', {  year:"numeric", month:"long", day:"numeric"});

    // Scroll to success message when form submitted
    const modalRef = useRef(null);

    const scrollTop = () => {
        modalRef.current.scrollIntoView();
    }

    return (
      <Marker icon={customMarkerIcon} position={coordinates}>
          <Popup minWidth={250}>
            <h5>{latestStep} - {cut.product.name}</h5>
            <p><FontAwesomeIcon icon={regular('calendar')} style={{marginRight:"7px"}} />{formattedDate}</p>
            {address && (
                <p><FontAwesomeIcon icon={regular('location-dot')} style={{marginRight:"7px"}} />{address} {street} {city}, {state} {zipcode}</p>
            )}
            <Row gutter={10}>
                {address && (
                    <Column width={1/2}>
                        <FlexWraper>
                            <ExternalLink target="_blank" to={'https://www.google.com/maps/dir/?api=1&destination=' + coordinates.join(',')}>
                                Directions
                            </ExternalLink>
                        </FlexWraper>
                    </Column>
                )}
                <Column width={1/2}>
                    <FlexWraper>
                        <Button onClick={toggleModal}>
                            Edit
                        </Button>
                        <Modal isShowing={isShowing} hide={toggleModal} title="Edit Entry" modalRef={modalRef}>
                            <EditCutForm cut={cut} refreshCuts={refreshCuts} latestStep={latestStep} formattedDate={formattedDate} setLoading={setLoading} toggleModal={toggleModal} scrollTop={scrollTop} />
                        </Modal>
                    </FlexWraper>
                </Column>
            </Row>
          </Popup>
          <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
              { weight }
          </Tooltip>
      </Marker>
  );
}

export default MapMarker;
