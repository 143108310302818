import React, {useState} from 'react';
import {MapContainer, TileLayer, useMap, useMapEvents} from 'react-leaflet';
import CutMarker from "./CutMarker";

function UpdateMapState({coordinates}) {
    const map = useMap();
    map.setView(coordinates);

    const [zoom, setZoom] = useState(12);

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoom(mapEvents.getZoom());
        },
    });

    return null;
}


function AddMarker({coordinates, cutMarkerDragendHandler, id, address, street, zipcode, state, city}) {
    const map = useMap();

    if (address === "") {
        return null;
    }

    map.setZoom(16);

    return (
        <CutMarker
            coordinates={coordinates}
            id={id}
            address={address}
            zipcode={zipcode}
            city={city}
            state={state}
            street={street}
            markerDragendHandler={cutMarkerDragendHandler}
        />
    )
}

function MapView({coordinates, cutMarkerDragendHandler, id, address, street, zipcode, state, city}) {

    if (coordinates[0] === undefined) {
        coordinates = [37.977432, -87.544054];
    }

    return (
    <MapContainer center={coordinates} zoom={12} scrollWheelZoom={true} style={{ height:'50vh', width:'100%' }}>
        <UpdateMapState coordinates={coordinates} />
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <AddMarker coordinates={coordinates} cutMarkerDragendHandler={cutMarkerDragendHandler} id={id} address={address} street={street} zipcode={zipcode} state={state} city={city} />
    </MapContainer>
    );
}

export default MapView;
