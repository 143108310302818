import {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import SEO from 'components/SEO/SEO';
import {useDavidApi} from 'utility/DavidApi/DavidApi';
import Container from 'components/Grid/Container';
import AddClientForm from "components/Form/AddClientForm";
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import useModal from 'components/Modal/useModal';
import DataTable from 'components/Table/DataTable';
import TableModal from 'components/Table/TableModal';
import DeleteClientForm from 'components/Form/DeleteClientForm';
import EditClientForm from 'components/Form/EditClientForm';

import { YellowButtonWrapper } from 'components/Button/Button.styles';

const columns = [
    {
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "projectsCount",
        label: "Projects",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                className: "text-center",
            }),
            setCellHeaderProps: (value) => ({
                className: "text-center",
            })
        }
    },
    {
        name: "address",
        label: "Address",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "address1",
        label: "Address Line 2",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "phone",
        label: "Phone",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "actions",
        label: "Actions",
        options: {
            filter: false,
            sort: false,
        }
    },
];

function Clients() {
    const api = useDavidApi();
    const [loading, setLoading] = useState(true);
    // const [tableData, setTableData] = useState([]);
    const newClientModal = useModal();
    const deleteClientModal = useModal();
    const editClientModal = useModal();
    const [deleteClientModalClient, setDeleteClientModalClient] = useState(null);
    const [editClientModalClient, setEditClientModalClient] = useState(null);

    useEffect(() => {
        setLoading(true);

        (async () => {
           await api.getClients();
        })();
    }, []);

    useEffect(() => {
        if (api.clients === null) {
            return;
        }

        setLoading(false);
        // newClientModal.hide();
        // deleteClientModal.hide();
        // editClientModal.hide();
    }, [api.clients]);

    // const refreshTableData = () => {
    //     if (api.clients === null) {
    //         return;
    //     }

    //     let newTableData = [];

    //     for (const client of api.clients) {
    //         newTableData.push( getTableRowDataForClient(client) );
    //     }

    //     setTableData(newTableData);
    //     setLoading(false);
    // }

    const deleteClientModalTriggerHandler = (client) => {
        setDeleteClientModalClient(client);
        deleteClientModal.show();
    }

    const editClientModalTriggerHandler = (client) => {
        setEditClientModalClient(client);
        editClientModal.show();
    }

    const getTableRowDataForClient = (client) => {
        let clientTableRowData = {
            ...client,
            ... {
                projectsCount: client?.projects?.length,
                actions: (
                    <div className="table-row-actions-container">
                        <Button
                            size={'sm'}
                            iconLeft={<FontAwesomeIcon icon={solid('edit')} />}
                            onClick={() => editClientModalTriggerHandler(client)}
                            style={{marginRight: '20px'}}
                        >
                            Edit
                        </Button>
                        <Button
                            size={'sm'}
                            iconLeft={<FontAwesomeIcon icon={solid('trash')} />}
                            onClick={() => deleteClientModalTriggerHandler(client)}
                        >
                            Delete
                        </Button>
                    </div>
                )
            }
        };

        return clientTableRowData;
    }

    return (
        <>
            <SEO title="Clients" />
            <Container vpadding={30}>
                <h1>Clients</h1>
                <Modal isShowing={newClientModal.isShowing} hide={newClientModal.hide} title="Add New Client">
                    <AddClientForm 
                        hideModal={newClientModal.hide}
                    />
                </Modal>
                <div>
                    <DataTable
                        columns={columns}
                        // data={tableData}
                        data={api?.clients?.length && api?.clients.map((client) => getTableRowDataForClient(client) )}
                        loading={loading}
                        options={{
                            sortOrder: {
                                name: 'name',
                                direction: 'asc',
                            }
                        }}
                    />
                    <Modal title="Edit Client" isShowing={editClientModal.isShowing} hide={editClientModal.hide}>
                        <EditClientForm 
                            client={editClientModalClient}
                            hideModal={editClientModal.hide}
                        />
                    </Modal>
                    <TableModal title="Delete Client" isShowing={deleteClientModal.isShowing} hide={deleteClientModal.hide}>
                        <DeleteClientForm
                            client={deleteClientModalClient}
                            hideModal={deleteClientModal.hide}
                        />
                    </TableModal>
                </div>
                <YellowButtonWrapper>
                    <Button 
                        onClick={newClientModal.show}
                        iconLeft={<FontAwesomeIcon icon={solid('plus')} />}
                    >
                            Add Client
                    </Button>
                </YellowButtonWrapper>
            </Container>
        </>
    )
}

export default Clients;
