import React, {useState} from 'react';
import {EditCutInfo} from './FormComponents/Form.styles';
import {SubmitButton} from 'components/Button/Button.styles';
import Column from "components/Grid/Column";
import Row from "components/Grid/Row";
import TextareaField from './FormComponents/TextareaField';
import Form from './FormComponents/Form';
import CheckboxField from './FormComponents/CheckboxField';
import FeetInchesForm from "./FeetInchesForm";
import Button from 'components/Button/Button';
import LoadingOverlay from 'components/Layout/MapView/LoadingOverlay/LoadingOverlay';
import { YellowButtonWrapper } from 'components/Button/Button.styles';

import {useDavidApi} from 'utility/DavidApi/DavidApi';
import {useMsal} from "@azure/msal-react";
// import { Cancel } from '@mui/icons-material';


function EditCutForm({cut, refreshCuts, formattedDate, latestStep, toggleModal, scrollTop }) {
    const api = useDavidApi();
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    const [inputs, setInputs] = useState({
        steps: cut.steps,
        actualLength: cut.actualLength,
        actualWidth: cut.actualWidth,
        actualDepth: cut.actualDepth,
        monoCurb: cut.monoCurb,
        flowableFill: cut.flowableFill,
        comments: cut.comments
    });
    const [isChecked, setChecked] = useState(true);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const client = cut?.project?.client;

    const toggleChecked = (e) => {
        setChecked(!isChecked);
        const currentDate = new Date();
        currentDate.setHours(0,0,0,0);

        let steps;
        if (e.target.checked) {
            steps = [...inputs.steps, {label: e.target.name, completedAt: currentDate}];
        } else {
            steps = inputs.steps.filter((item) => {
                return item.label !== e.target.name;
            });
        }

        setInputs({...inputs, steps: steps});
    }

    // const handleChange = (e) => {
    //   const name = e.target.name;
    //   const value = e.target.value;
    //   setInputs({...inputs, [name]: value});
    // }

    // const handleMeasurementChange = (uniqueName, totalInches) => {
    //     setInputs({...inputs, [uniqueName]: totalInches});
    // }

    const handleSubmit = (values, id) => {
        scrollTop();

        (async () => {
            setLoading(true);
            await api.patchCut(values, id);
            setTimeout(() => {
                toggleModal();
                setLoading(false);
            }, 
                1500
            );
            
            refreshCuts();
        })();
    }

    return (
        <>
            <Form 
                successMessage='Cut updated!' 
                id={cut.id} 
                values={inputs} 
                setValues={setInputs}
                errors={errors}
                setErrors={setErrors}
                asyncSubmitHandler={handleSubmit}
            >
            {loading && (
                <LoadingOverlay>
                    <h2>
                        Cut updated! Refreshing...
                    </h2>
                </LoadingOverlay>
            )}
                <Row style={{marginTop:'10px'}}>
                    <Column>
                        <h5>Client: </h5>
                        {client === undefined ? <EditCutInfo>No Client Provided</EditCutInfo> : <EditCutInfo>{client.name}</EditCutInfo>}
                    </Column>
                </Row>
                <Row>
                    <Column width={1/2}>
                        <h5>Order Date: </h5>
                        <EditCutInfo>{formattedDate}</EditCutInfo>
                    </Column>
                    <Column width={1/2}>
                        <h5>Work Order/SAP Number: </h5>
                        <EditCutInfo>{cut.project.projectNumber}</EditCutInfo>
                    </Column>
                </Row>
                <Row>
                    <Column width={1/2}>
                        <h5>Original Measurements:</h5>
                        {cut.originalWidth && cut.originalLength && cut.originalDepth && (
                            <EditCutInfo>{cut.originalLength} in. x {cut.originalWidth} in. x {cut.originalDepth} in.</EditCutInfo>
                        )}
                        {!cut.originalLength && (
                            <EditCutInfo>There are no original measurements.</EditCutInfo>
                        )}
                    </Column>
                    <Column width={1/2}>
                        <h5>Address:</h5>
                        {cut.address && (
                            <EditCutInfo>{cut.address} {cut.street} {cut.city}, {cut.state} {cut.zipcode}</EditCutInfo>
                        )}
                        {!cut.address && (
                            <EditCutInfo>There is no address for this cut.</EditCutInfo>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column width={1/2}>
                        <h5>Current Step: </h5>
                        <EditCutInfo>{latestStep} - {cut.product.name}</EditCutInfo>
                    </Column>
                    {latestStep === 'Digout' && (
                        <Column width={1/2}>
                            <CheckboxField
                                onChange={toggleChecked}
                                checked={!isChecked}
                                value={cut.steps}
                                name="Digout"
                                label="Digout Completed?"
                            />
                        {cut.product.id === 3 && (
                            <>
                                <CheckboxField
                                    label="Mono Curb?"
                                    name={'monoCurb'}
                                />
                            </>
                        )}
                        </Column>
                    )}
                    {latestStep === 'Base' && (
                        <Column width={1/2}>
                            <CheckboxField
                                onChange={toggleChecked}
                                checked={!isChecked}
                                value={cut.steps}
                                name="Base"
                                label="Base Completed?"
                            />
                        </Column>
                    )}
                    {latestStep === 'Surface' && (
                        <Column width={1/2}>
                            <CheckboxField
                                onChange={toggleChecked}
                                checked={!isChecked}
                                value={cut.steps}
                                name="Surface"
                                label="Surface Completed?"
                            />
                        </Column>
                    )}
                </Row>
                {latestStep === 'Base' && cut.product.id !== 3 && (
                <>
                    <h3>Update Measurements</h3>
                        <EditCutInfo>
                            <CheckboxField
                                label="Flowable Fill"
                                name={'flowableFill'}
                            />
                        </EditCutInfo>
                    <Row gutter={15} style={{marginBottom:'10px'}}>
                        <FeetInchesForm label="Length" uniqueName="actualLength" cut={cut} />
                        <FeetInchesForm label="Width" uniqueName="actualWidth" cut={cut} />
                        <FeetInchesForm label="Depth" uniqueName="actualDepth" cut={cut} />
                    </Row>
                </>
                )}
                {latestStep === 'Surface' && cut.product.id !== 3 && (
                <>
                    <h3>Update Measurements</h3>
                    <CheckboxField
                        label="Flowable Fill"
                        name={'flowableFill'}
                    />
                    <Row gutter={15} style={{marginBottom:'10px'}}>
                        <FeetInchesForm label="Length" uniqueName="actualLength" cut={cut} />
                        <FeetInchesForm label="Width" uniqueName="actualWidth" cut={cut} />
                        <FeetInchesForm label="Depth" uniqueName="actualDepth" cut={cut} />
                    </Row>
                </>
                )}
                    <TextareaField
                        label="Comments"
                        name="comments"
                    />

                    <h4 style={{marginBottom:'25px', marginTop:'20px'}}>Completed and approved by: {name}</h4>

                <Row centered>
                    <Column width={3/4}>
                        <YellowButtonWrapper>
                            <SubmitButton>Update</SubmitButton>
                        </YellowButtonWrapper>
                    </Column>
                    <Column width={1/4}>
                        <Button target="_blank" to={'/projects/' + cut.project.id + '/edit'}>Add Additional Cuts</Button>
                    </Column>
                </Row>
            </Form>
        </>
    )
  }

export default EditCutForm;
