import React, {useEffect, useState} from 'react';
import Form from 'components/Form/FormComponents/Form';
import {cutStepLabels} from './cutStepLabels';
import Column from 'components/Grid/Column';
import RadioCheckbox from 'components/Form/FormComponents/RadioCheckbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';

const CutLegendControl = ({
    handleFilterSubmit, 
    inputs, 
    setInputs, 
    // initialState, 
    // sidebarFilterSubmitted, 
    // setSidebarFilterSubmitted
}) => {
    const [selectedState, setSelectedState] = useState(false);

    const toggleSelected = (e) => {

        setSelectedState(!selectedState);

        let value = e.target.value;
        if (value === "all") {
            delete inputs.step;
            setInputs(inputs);
        } else {
            setInputs({...inputs, step: value});
        }
    }

    useEffect(() => { 
        handleFilterSubmit();
    }, [selectedState]);

    return (
        <>
            <div className="leaflet-bottom leaflet-left">
                <div className='leaflet-control info legend'>
                    <Form values={inputs} setValues={setInputs} style={{paddingTop: '0'}}>
                            {cutStepLabels.map(({ name, icon }) => {
                                return (
                                    <Column width={1/5} key={name}>
                                        <RadioCheckbox
                                            id={`${name + icon}`}
                                            name="radio-button"
                                            label={name}
                                            value={name}
                                            iconLeft={icon}
                                            style={{marginRight:'10px', marginLeft:'10px'}}
                                            selected={selectedState}
                                            onClick={(e) => toggleSelected(e)}
                                        />
                                    </Column>
                                    // <Column width={1/5}>
                                    //     <CheckboxField
                                    //         id={`${name}`}
                                    //         type="radio"
                                    //         name="checkbox"
                                    //         checked={checkedState[index]}
                                    //         value={name}
                                    //         label={name}
                                    //         onChange={(e) => toggleChecked(index, e)}
                                    //         iconLeft={icon}
                                    //         style={{marginRight:'5px', marginLeft:'5px'}}
                                    //     />
                                    // </Column>
                                );
                            })}
                            <Column width={5/5}>
                                <RadioCheckbox
                                    id="all"
                                    name="radio-button"
                                    label="View All"
                                    value="all"
                                    iconLeft={(<FontAwesomeIcon icon={solid('eye')} />)}
                                    style={{marginRight:'10px', marginLeft:'10px'}}
                                    selected={selectedState}
                                    onClick={(e) => toggleSelected(e)}
                                />
                            </Column>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default CutLegendControl;
