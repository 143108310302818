import React from 'react';
import styled from 'styled-components';
import {getColorFromSlug} from 'utility/colors';

const SidebarButton = ({
    children,
    onClick,
    iconLeft,
    iconRight,
    ...otherProps
}) => {

    if (iconLeft) {
        iconLeft = React.cloneElement(iconLeft, {
            ...iconLeft.props,
            ...{
                className: `${iconLeft?.props?.className || ''} icon-left`
            },
        });
    }

    if (iconRight) {
        iconRight = React.cloneElement(iconRight, {
            ...iconRight.props,
            ...{
                className: `${iconRight?.props?.className || ''} icon-right`
            },
        });
    }

    return (
        <StyledSidebarButton onClick={onClick} {...otherProps}>
            {iconLeft}
            {children}
            {iconRight}
        </StyledSidebarButton>
    );
}

export default SidebarButton;

const StyledSidebarButton = styled.button`
    color: ${getColorFromSlug('david-yellow')};
    background: ${getColorFromSlug('david-black')};
    border-radius: 5px;
    padding: 10px;
    font-size: 22px;
    font-family: 'Oswald', serif;
    width: 100%;
    max-width: 175px;
    text-align: center;
    border: none;
    box-shadow: 0 2px 8px 0 #888;

    &:hover, &:focus {
        background: #fff;
        color: ${getColorFromSlug('david-black')} !important;
        cursor: pointer;
    }

    >svg.icon-left {
        margin-right: 10px;
    }

    >svg.icon-right {
        margin-left: 10px;
    }
`
