import { useState } from 'react';

const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function show() {
        setIsShowing(true);
    }

    function hide() {
        setIsShowing(false);
    }

    function toggle() {
        setIsShowing(!isShowing);
    }

    return {
        isShowing,
        show,
        hide,
        toggle,
        toggleModal: toggle,
    }
};

export default useModal;