import React from "react";
import {useIsAuthenticated} from "@azure/msal-react";
import {SignInButton} from "../SignInButton";
import Container from "components/Grid/Container";
import SEO from "components/SEO/SEO";
import {LogoLink} from "components/Layout/Header/Header.styles";
import {ReactComponent as Logo} from 'images/logo.svg';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Anonymous = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div>
            <SEO title="Login" />
                <Container className="login" style={{height: '100vh', width: '100vw'}}>
                    <LogoLink to="/" className={"logoLink"}>
                        <Logo style={{ fill: '#fff' }} />
                    </LogoLink>
                        { isAuthenticated ? <span>Signed In</span> : <SignInButton /> }
                    <br />
                    <br />
                    {props.children}
                </Container>
        </div>
    );
};
