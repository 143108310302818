import React from 'react';
import { StyledButton } from './Button.styles';
import { Link } from 'react-router-dom';

const Button = ({
    as,
    to,
    target,
    onClick,
    type = 'button',
    size = 'default',
    iconLeft,
    iconRight,
    children,
    ...otherProps
}) => {
    let styledButtonProps = {
        ...otherProps,
        ...{
            as: as,
            to: to,
            target: target,
        }
    };

    if (to && !as) {
        styledButtonProps.as = Link;
    }

    if (!styledButtonProps.as) {
        styledButtonProps.type = type;
    }

    if (iconLeft) {
        iconLeft = React.cloneElement(iconLeft, {
            ...iconLeft.props,
            ...{
                className: `${iconLeft?.props?.className || ''} icon-left`
            },
        });
    }

    if (iconRight) {
        iconRight = React.cloneElement(iconRight, {
            ...iconRight.props,
            ...{
                className: `${iconRight?.props?.className || ''} icon-right`
            },
        });
    }

    return (
            <StyledButton $size={size} onClick={onClick} {...styledButtonProps}>
                {iconLeft}
                {children}
                {iconRight}
            </StyledButton>
    );
}

export default Button;
