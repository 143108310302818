import styled from "styled-components";

export const StyledFormField = styled.div`
    width: 100%;

    ${({ inline }) => inline && `
        display: flex;
        align-items: center;
    `}

    ${({ noMargin }) => noMargin && `
        margin-bottom: 0 !important;
    `}
`;

export const StyledLabel = styled.label`
    display: block;
    font-family:'Oswald', serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;

    ${({ inline }) =>
        inline
        ? `
            margin-right: 10px;
        `
        : `
            margin-bottom: 5px;
            margin-top: 5px;
        `
    }
`;

export const StyledCheckboxFormLabel = styled.label`
  font-family:'Oswald', serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
`;
