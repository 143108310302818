import { StyledFuzzySearchHighlight } from "./FuzzySearchHighlight.styles";

const FuzzySearchHighlight = ({
    children,
    ...otherProps
}) => {
    return (
        <StyledFuzzySearchHighlight {...otherProps}>
            {children}
        </StyledFuzzySearchHighlight>
    );
}

export default FuzzySearchHighlight;