import {useState} from 'react'

import SEO from 'components/SEO/SEO';
import Container from 'components/Grid/Container';
import Form from 'components/Form/FormComponents/Form';
import { SubmitButton } from 'components/Button/Button.styles';
import ArrayField from 'components/Form/FormComponents/ArrayField';
import TextField from 'components/Form/FormComponents/TextField';
import NumberField from 'components/Form/FormComponents/NumberField';
import Row from 'components/Grid/Row';
import Column from 'components/Grid/Column';
import Testcomponent from 'components/Testcomponent';


function Test() {
    const [myFormValues, setMyFormValues] = useState({
        mygroup: [
            {},
        //     // {
        //     //     'textfield': 'foo',
        //     //     'textfield2': 'foo2',
        //     // },
        //     // {
        //     //     'textfield': 'bar',
        //     //     'textfield2': 'bar2',
        //     // },
        //     // {
        //     //     'textfield': 'baz',
        //     //     'textfield2': 'baz2',
        //     // },
        ],
    });

    const submitHandler = async (values) => {
        // console.log(values);
        // return false;
    }

    return (
        <>
            <SEO title="Test" />
            <Container vpadding={30}>
                <h1>Test</h1>

                <Form values={myFormValues} setValues={setMyFormValues} asyncSubmitHandler={submitHandler}>
                    <ArrayField
                        name="mygroup"
                        label="My Group"
                        entryLabelSingular="Main Item"
                        entryComponent={
                            <Row gutter={30}>
                                <Column width={1 / 2}>
                                    <TextField
                                        label="Text Field"
                                        name="textfield"
                                    />
                                </Column>
                                <Testcomponent />
                            </Row>
                            // <>
                            //     <TextField
                            //         label="Text Field"
                            //         name="textfield"
                            //     />
                            //     <TextField
                            //         label="Text Field2"
                            //         name="textfield2"
                            //     />
                            //     <NumberField
                            //         label="Number Field"
                            //         name="numberfield"
                            //     />
                            //     <ArrayField
                            //         name="mygroupnested"
                            //         label="My Group Nested"
                            //         entryLabelSingular="Nested Item"
                            //         entryComponent={(
                            //             <>
                            //                 <TextField
                            //                     label="Text Field"
                            //                     name="textfield"
                            //                 />
                            //                 <TextField
                            //                     label="Text Field2"
                            //                     name="textfield2"
                            //                 />
                            //                 <NumberField
                            //                     label="Number Field"
                            //                     name="numberfield"
                            //                 />
                            //             </>
                            //         )}
                            //     />
                            // </>
                        }
                    />

                    <SubmitButton>Submit</SubmitButton>
                </Form>
            </Container>
        </>
    )
}

export default Test;
