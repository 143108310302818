import FuzzySearchHighlightsWrapper from "./FuzzySearchHighlightsWrapper";
import { StyledFuzzySearchSelectItem } from "./FuzzySearchSelectItem.styles";

const FuzzySearchSelectItem = ({
    itemKey,
    label = null,
    value = null,
    hovered = false,
    onHoverHandler = null,
    onSelectHandler = null,
    highlightMatchIndices = [],
    ...otherProps
}) => {
    
    const onMouseEnterHandler = () => {
        if (!onHoverHandler) {
            return;
        }

        onHoverHandler(itemKey);
    }

    const onClickHandler = () => {
        if (!onSelectHandler) {
            return;
        }

        onSelectHandler(itemKey);
    }

    return (
        <StyledFuzzySearchSelectItem
            hovered={hovered}
            onSelect={onSelectHandler}
            onMouseEnter={onMouseEnterHandler}
            onClick={onClickHandler}
        >
            <FuzzySearchHighlightsWrapper
                originalValue={label}
                matchIndices={highlightMatchIndices}
            />
        </StyledFuzzySearchSelectItem>
    )
}

export default FuzzySearchSelectItem;