import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


import SEO from 'components/SEO/SEO';
import Container from 'components/Grid/Container';
import davidApi from 'utility/DavidApi/DavidApi.js.OLD';
import Button from 'components/Button/Button';
import DataTable from 'components/Table/DataTable';

const columns = [
    // {
    //     name: "id",
    //     label: "ID",
    //     options: {
    //         filter: true,
    //         sort: true,
    //     }
    // },
    {
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "projectsCount",
        label: "Projects",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                className: "text-center",
            }),
            setCellHeaderProps: (value) => ({
                className: "text-center",
            })
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: false,
            sort: false,
        }
    },
];


const Test2 = () => {
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    // force refresh on every page load
    useEffect( () => {
        refreshTableData();
    }, []);

    const refreshTableData = async () => {
        setLoading(true);
        await davidApi.getClients();
        
        let newTableData = [];

        for (const client of davidApi.clients) {
            newTableData.push( getTableRowDataForClient(client) );
        }

        setTableData(newTableData);
        setLoading(false);
    }

    const getTableRowDataForClient = (client) => {
        let clientTableRowData = {
            ...client,
            ... {
                projectsCount: client?.projects?.length,
                actions: (
                    <div className="table-row-actions-container">
                        <Button
                            size={'xs'}
                            iconLeft={<FontAwesomeIcon icon={solid('edit')} />}
                        >
                            Edit
                        </Button>
                        <Button
                            size={'xs'}
                            iconLeft={<FontAwesomeIcon icon={solid('trash')} />}
                        >
                            Delete
                        </Button>
                    </div>
                )
            }
        };

        return clientTableRowData;
    }

    return (
        <>
            <SEO title="Test2" />
            <Container vpadding={30}>
                <h1>Test2</h1>

                <DataTable 
                    columns={columns}
                    data={tableData}
                    loading={loading}
                    options={{
                        sortOrder: {
                            name: 'name',
                            direction: 'asc',
                        }
                    }}
                />
            </Container>
        </>
    )
}

export default Test2;
