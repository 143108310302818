import { breakpoints } from 'utility/screen-width';
import { StyledColumn } from './Column.styles';

const Column = ({
    gutter,
    width,
    children,
    ...otherProps
}) => {
    let widths = {};

    for (const breakpoint_label in breakpoints) {
        if ( otherProps.hasOwnProperty(breakpoint_label) ) {
            widths[breakpoint_label] = otherProps[breakpoint_label];
            delete otherProps[breakpoint_label];
        }
    }

    return (
        <StyledColumn
            gutter={gutter}
            $width={width}
            {...{
                $widths: widths,
                ...otherProps
            }}
            
        >
            {children}
        </StyledColumn>
    );
}

export default Column;
