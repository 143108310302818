import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledIconWrapper, StyledMessageWrapper, StyledPageMessage } from './PageMessage.style';

const TYPE_PROPS = {
    success: {
        icon: icon({name:'check', style:'regular'}),
        borderColor: 'success-border',
        bgColor: 'success-bg',
        textColor: 'success-text',
        iconColor: 'success-text',
    },
    error: {
        icon: icon({name:'exclamation-triangle'}),
        borderColor: 'error-border',
        bgColor: 'error-bg',
        textColor: 'error-text',
        iconColor: 'error-text',
    },
    info: {
        icon: icon({name:'info-circle'}),
        borderColor: 'info-border',
        bgColor: 'info-bg',
        textColor: 'info-text',
        iconColor: 'info-text',
    },
};

const PageMessage = ({
    type = 'success',
    icon = null,
    borderColor = null,
    bgColor = null,
    textColor = null,
    iconColor = null,
    children,
    ...otherProps
}) => {
    if ( TYPE_PROPS.hasOwnProperty(type) ) {
        icon = icon || TYPE_PROPS[type].icon;
        borderColor = borderColor || TYPE_PROPS[type].borderColor;
        bgColor = bgColor || TYPE_PROPS[type].bgColor;
        textColor = textColor || TYPE_PROPS[type].textColor;
        iconColor = iconColor || TYPE_PROPS[type].iconColor;
    }

    return (
        <StyledPageMessage
            $borderColor={borderColor}
            $bgColor={bgColor}
            {...otherProps}
        >
            {icon && (
                <StyledIconWrapper $iconColor={iconColor}>
                    <FontAwesomeIcon icon={icon} />
                </StyledIconWrapper>
            )}
            <StyledMessageWrapper $textColor={textColor}>
                {children}
            </StyledMessageWrapper>
        </StyledPageMessage>
    );
}

export default PageMessage;
