import { StyledFormField, StyledLabel } from "./FormField.styles";
import FormFieldError from "./FormFieldError";

const FormField = ({
    __TYPE = 'FormField',
    label = null,
    required = false,
    id = null,
    inline = false,
    hasError = false,
    errorMessage,
    children,
    ...otherProps
}) => (
    <StyledFormField inline={inline} {...otherProps}>
        {label && (
            <StyledLabel inline={inline} title={required ? `This field is required.` : undefined} htmlFor={id}>
                {label}
                {required && ` *`}
            </StyledLabel>
        )}

        {children}

        {errorMessage && (
            <FormFieldError>
                {errorMessage}
            </FormFieldError>
        )}
    </StyledFormField>
);

export default FormField;