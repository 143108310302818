import ReactDOM from 'react-dom';
import { StyledTableModal, TableModalHeader, ModalOverlay, ModalWrapper, ModalCloseButton, ModalBody, ModalFooter } from 'components/Modal/Modal.styles';

const TableModal = ({ 
    isShowing,
    title = null,
    hide,
    children,
}) => isShowing ? ReactDOM.createPortal(

    <>
        <ModalOverlay />
        <ModalWrapper>
            <StyledTableModal>
                <TableModalHeader>
                    <h3>{title}</h3>
                    <ModalCloseButton onClick={hide}>
                        <span aria-hidden="true">&times;</span>
                    </ModalCloseButton>
                </TableModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter />
            </StyledTableModal>
        </ModalWrapper>
    </>,
    document.body

) : null;

export default TableModal;