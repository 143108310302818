import {createContext, useContext, useState} from "react";
import axios from 'axios';
import {msalConfig, protectedResources} from "../../authConfig";
import {getClaimsFromStorage} from "../Authentication/storageUtils";
import {InteractionRequiredAuthError} from "@azure/msal-browser";


const DavidApiContext = createContext();

const API_BASE_URL = process.env.REACT_APP_DAVID_API_URL;

const ENDPOINT_CLIENTS = '/clients';
const ENDPOINT_CUTS = '/cuts';
const ENDPOINT_CUT_STEPS = '/cut_steps';
const ENDPOINT_PRODUCTS = '/products';
const ENDPOINT_PROJECTS = '/projects';

const DavidApiContextProvider = (props) => {
    const [clients, setClients] = useState(null);
    const [cuts, setCuts] = useState(null);
    const [cutSteps, setCutSteps] = useState(null);
    const [projects, setProjects] = useState(null);
    const [products, setProducts] = useState(null);

    const DEFAULT_REQUEST_CONFIG = {
        headers: {
            accept: 'application/json'
        }
    };

    const DEFAULT_PATCH_REQUEST_CONFIG = {
        headers: {
            ...DEFAULT_REQUEST_CONFIG.headers,
            'Content-Type': 'application/merge-patch+json'
        }
    };
    const checkAuthentication = async (msalInstance) => {
        const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = msalInstance.getAllAccounts();
        const resource = new URL(protectedResources.patchAPI.endpoint).hostname;

        if (!activeAccount && accounts.length === 0) {
            /*
            * User is not signed in. Throw error or wait for user to login.
            * Do not attempt to log a user in outside of the context of MsalProvider
            */
        }

        const request = {
            scopes: protectedResources.patchAPI.scopes,
            account: activeAccount,
            claims: activeAccount && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${resource}`)
                ? window.atob(getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${resource}`))
                : undefined, // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
        };

        const authResult = await msalInstance.acquireTokenSilent(request)
            .catch(function (error) {
            //Acquire token silent failure, and send an interactive request
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(request);
            }
        });

        return authResult.accessToken;
    };

    async function get(endpoint, config = {}) {
        const token = await checkAuthentication(props.instance);
        if (token === null) {
            return;
        }
        let withAccessToken = {
            headers: {
                ...DEFAULT_REQUEST_CONFIG.headers,
                Authorization: 'Bearer ' + token
            }
        }
        const mergedConfig = {...withAccessToken, ...config};
        const response = await axios.get(`${API_BASE_URL}${endpoint}`, mergedConfig);
        return response?.data;
    }

    async function post(endpoint, config = {}, data = {}) {
        const token = await checkAuthentication(props.instance);
        if (token === null) {
            return;
        }
        let withAccessToken = {
            headers: {
                ...DEFAULT_REQUEST_CONFIG.headers,
                Authorization: 'Bearer ' + token
            }
        }
        const mergedConfig = {...withAccessToken, ...config};
        const response = await axios.post(`${API_BASE_URL}${endpoint}`, data, mergedConfig);
        return response?.data;
    }

    async function patch(endpoint, config = {}, data = {}) {
        const token = await checkAuthentication(props.instance);
        if (token === null) {
            return;
        }
        let withAccessToken = {
            headers: {
                ...DEFAULT_PATCH_REQUEST_CONFIG.headers,
                Authorization: 'Bearer ' + token
            }
        }
        const mergedConfig = {...withAccessToken, ...config};
        const response = await axios.patch(`${API_BASE_URL}${endpoint}`, data, mergedConfig);
        return response?.data;
    }

    async function put(endpoint, config = {}, data = {}) {
        const token = await checkAuthentication(props.instance);
        if (token === null) {
            return;
        }
        let withAccessToken = {
            headers: {
                ...DEFAULT_REQUEST_CONFIG.headers,
                Authorization: 'Bearer ' + token
            }
        }
        const mergedConfig = {...withAccessToken, ...config};
        const response = await axios.put(`${API_BASE_URL}${endpoint}`, data, mergedConfig);
        return response?.data;
    }

    // can't name a variable or function `delete`
    async function deleteRequest(endpoint, config = {}) {
        const token = await checkAuthentication(props.instance);
        if (token === null) {
            return;
        }
        let withAccessToken = {
            headers: {
                ...DEFAULT_REQUEST_CONFIG.headers,
                Authorization: 'Bearer ' + token
            }
        }
        const mergedConfig = {...withAccessToken, ...config};
        const response = await axios.delete(`${API_BASE_URL}${endpoint}`, mergedConfig);
        return response;
    }

    // CLIENTS
    async function getClients() {
        if (clients === null) {
            await fetchClients();
        }

        return clients;
    }

    async function fetchClients() {
        const response = await get(ENDPOINT_CLIENTS);
        setClients(response);
        return response;
    }

    async function patchClient(data = {}, id = null) {
        const response = await patch(`${ENDPOINT_CLIENTS}/${id}`, {}, data);

        if (response) {
            fetchClients();
        }

        return response;
    }

    async function postClient(data = {}) {
        const response = await post(ENDPOINT_CLIENTS, {}, data);

        if (response) {
            // if (clients?.length) {
            //     let updatedClients = [...clients];
            //     updatedClients.push(response);
            //     setClients(updatedClients);
            // } else {
            //     fetchClients();
            // }

            fetchClients();
        }

        return response;
    }

    async function deleteClient(id = null) {
        if (!id) {
            return false;
        }

        const response = await deleteRequest(`${ENDPOINT_CLIENTS}/${id}`);

        if (response.status === 204) {
            fetchClients();
            // this.clients = this.clients.filter(client => client.id !== id);
        }

        return response;
    }

    // CUTS
    async function getCuts(parameters = {}) {
        if (cuts === null) {
            await fetchCuts(parameters);
        }

        return cuts;
    }

    async function fetchCuts(parameters = {}) {
        const response = await get(ENDPOINT_CUTS, {
            params: parameters,
        });
        setCuts(response);
        return response;
    }

    async function patchCut(data = {}, id = null) {
        const response = await patch(`${ENDPOINT_CUTS}/${id}`, {}, data);

        if (response) {
            fetchCuts();
        }

        return response;
    }

    async function postCut(data = {}) {
        const response = await post(ENDPOINT_CUTS, {}, data);

        if (response) {
            fetchCuts();
        }

        return response;
    }

    async function searchAllCuts(parameters) {
        return await get(ENDPOINT_CUTS, {
            params: parameters,
        });
    }

    async function putReview(id = null, fetchParameters = {}) {
        if (!id) {
            return false;
        }

        const response = await patch(`${ENDPOINT_CUTS}/${id}`, {}, {'reviewed': new Date()});

        if (response) {
            fetchCuts(fetchParameters);
        }

        return response;
    }

    // CUT STEPS
    async function getCutSteps() {
        if (cutSteps === null) {
            await fetchCutSteps();
        }

        return cutSteps;
    }

    async function fetchCutSteps() {
        const response = await get(ENDPOINT_CUT_STEPS);
        setCutSteps(response);
        return response;
    }

    async function postCutStep(data = {}) {
        const response = await post(ENDPOINT_CUT_STEPS, {}, data);

        if (response) {
            fetchCutSteps();
        }

        return response;
    }

    // PROJECTS
    async function getProjects() {
        if (projects === null) {
            await fetchProjects();
        }

        return projects;
    }

    async function fetchProject(id) {
        const response = await get(`${ENDPOINT_PROJECTS}/${id}`);
        return response;
    }

    async function fetchProjects() {
        const response = await get(ENDPOINT_PROJECTS);
        setProjects(response);
        return response;
    }

    async function postProject(data = {}) {
        const response = await post(ENDPOINT_PROJECTS, {}, data);

        if (response) {
            fetchProjects();
        }

        return response;
    }

    async function putProject(data = {}) {
        if (data?.id === null) {
            return;
        }

        const response = await put(`${ENDPOINT_PROJECTS}/${data.id}`, {}, data);

        if (response) {
            fetchProjects();
        }

        return response;
    }

    // PRODUCTS
    async function getProducts() {
        if (products === null) {
            await fetchProducts();
        }

        return products;
    }

    async function fetchProducts() {
        const response = await get(ENDPOINT_PRODUCTS);
        setProducts(response);
        return response;
    }

    async function postProduct(data = {}) {
        const response = await post(ENDPOINT_PRODUCTS, {}, data);

        if (response) {
            fetchProducts();
        }

        return response;
    }

    return (
        <DavidApiContext.Provider value={{
            clients,
            getClients,
            fetchClients,
            postClient,
            patchClient,
            deleteClient,

            cuts,
            getCuts,
            fetchCuts,
            postCut,
            patchCut,
            searchAllCuts,
            putReview,

            projects,
            getProjects,
            fetchProjects,
            fetchProject,
            postProject,
            putProject,

            products,
            getProducts,
            fetchProducts,
            postProduct,
        }}>
            {props.children}
        </DavidApiContext.Provider>
    );
};

const useDavidApi = () => useContext(DavidApiContext);

export { useDavidApi, DavidApiContextProvider };
