import {createRoot} from 'react-dom/client';
import Layout from 'components/Layout/Layout';
import {BrowserRouter} from "react-router-dom";
import {DavidApiContextProvider} from "utility/DavidApi/DavidApi";
import {EventType, PublicClientApplication} from '@azure/msal-browser';
import {msalConfig, protectedResources} from './authConfig';
import {MsalProvider} from '@azure/msal-react';
import {getClaimsFromStorage} from "./utility/Authentication/storageUtils";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    if (msalInstance.getActiveAccount() != null) {
        msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
    } else {
        const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = msalInstance.getAllAccounts();
        const resource = new URL(protectedResources.patchAPI.endpoint).hostname;

        const request = {
            scopes: protectedResources.patchAPI.scopes,
            account: activeAccount,
            claims: activeAccount && getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${resource}`)
                ? window.atob(getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${activeAccount.idTokenClaims.oid}.${resource}`))
                : undefined, // e.g {"access_token":{"xms_cc":{"values":["cp1"]}}}
        };

        msalInstance.acquireTokenSilent(request);
    }
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <DavidApiContextProvider instance={msalInstance} >
                <Layout />
            </DavidApiContextProvider>
        </MsalProvider>
    </BrowserRouter>
);
