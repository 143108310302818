import FuzzySearchHighlight from "./FuzzySearchHighlight";

const FuzzySearchHighlightsWrapper = ({
    originalValue = '',
    // highlighted = false,
    matchIndices = [],
    ...otherProps
}) => {
    let valueToDisplay = originalValue;

    if (matchIndices.length) {
        let highlightedValue = [];
        let previousEndIndex = 0;
        let key = 0;

        for (let indexPair of matchIndices) {
            if (previousEndIndex < indexPair[0]) {
                const nonHighlightedText = originalValue.slice(previousEndIndex, indexPair[0]);
                highlightedValue.push(nonHighlightedText);
            }

            const highlightedText = originalValue.slice(indexPair[0], indexPair[1]+1);
            highlightedValue.push(<FuzzySearchHighlight key={key}>{highlightedText}</FuzzySearchHighlight>);

            previousEndIndex = indexPair[1]+1;
            key++;
        }

        if (previousEndIndex < originalValue.length) {
            const nonHighlightedText = originalValue.slice(previousEndIndex, originalValue.length);
            highlightedValue.push(nonHighlightedText);
        }

        valueToDisplay = highlightedValue;
    }

    return (
        <div {...otherProps}>
            {valueToDisplay}
        </div>
    );
}

export default FuzzySearchHighlightsWrapper;