import {forwardRef, useEffect, useState} from 'react';
import ClearInput from 'components/Form/FormComponents/ClearInput';

const FuzzySearchInput = forwardRef( ({
    onQueryChange = null,
    timeoutDuration = 50,
    placeholder = "Begin typing to search...",
    ...otherProps
}, ref) => {
    const [query, setQuery] = useState('');
    const [fuzzySearchTimeout, setFuzzySearchTimeout] = useState(null);

    useEffect(() => {
        // console.log('query =', query);
        if (!onQueryChange || typeof onQueryChange !== 'function') {
            return;
        }

        if (fuzzySearchTimeout !== null) {
            // console.log('clearTimeout');
            clearTimeout(fuzzySearchTimeout);
            setFuzzySearchTimeout(null);
        }
    }, [query]);

    useEffect(() => {
        // console.log('fuzzySearchTimeout =', fuzzySearchTimeout);
        if (!onQueryChange || typeof onQueryChange !== 'function') {
            return;
        }

        if (fuzzySearchTimeout === null) {
            // console.log('setFuzzySearchTimeout');
            setFuzzySearchTimeout( setTimeout( () => onQueryChange(query), timeoutDuration ) );
        }
    }, [fuzzySearchTimeout]);

    const onChangeHandler = (e) => {
        const val = e.target.value;

        if (val !== query) {
            setQuery(val);
        }
    }

    return (
        <ClearInput
            ref={ref}
            value={query}
            onChange={onChangeHandler}
            onClear={() => setQuery('')}
            placeholder={placeholder}
            isFormField={false}
            noMargin
            {...otherProps}
        />
    );
} );

export default FuzzySearchInput;
