import styled from "styled-components";

export const StyledFormFieldGroup = styled.div`
    padding: 15px;
    background: #f2f2f2;
    margin-bottom: 15px;

    ${({ noMargin }) => noMargin && `
        margin-bottom: 0 !important;
    `}
`;

export const StyledFormFieldGroupLabel = styled.label`
    display: block;
    font-family:'Oswald', serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 15px;
`;