export function convertAddressComponentsToNamedProperties(place) {
    let address_properties = {};

    if ( !place?.address_components ) {
        return;
    }

    for (const address_component of place.address_components) {
        if (!address_component?.types) {
            continue;
        }

        for (const type of address_component.types) {
            if (!address_component?.long_name && !address_component?.short_name) {
                continue;
            }

            address_properties[type] = {};

            if (address_component?.long_name) {
                address_properties[type]['long_name'] = address_component.long_name;
            }

            if (address_component?.short_name) {
                address_properties[type]['short_name'] = address_component.short_name;
            }
        }
    }

    return address_properties;
}