import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledInputAddon, StyledInputWithAddon } from './InputWithAddon.styles';

const InputWithAddon = ({
    iconLeft = null,
    iconRight = null,
    iconLeftClickHandler = null,
    iconRightClickHandler = null,
    children,
    ...otherProps
}) => {
    return (
        <StyledInputWithAddon hasIconLeft={!!iconLeft} hasIconRight={!!iconRight} {...otherProps}>
            {children}
            {iconLeft && (
                <StyledInputAddon side="left" onClick={iconLeftClickHandler}>
                    <FontAwesomeIcon icon={iconLeft} />
                </StyledInputAddon>
            )}
            {iconRight && (
                <StyledInputAddon side="right" onClick={iconRightClickHandler}>
                    <FontAwesomeIcon icon={iconRight} />
                </StyledInputAddon>
            )}
        </StyledInputWithAddon>
    );
}

export default InputWithAddon;