import styled, {css} from 'styled-components';
import {getColorFromSlug} from 'utility/colors';

const BUTTON_SIZE_DATA = {
    default: {
        height: '50px',
        height_int: 50,
        horizontal_padding: '10px',
        horizontal_padding_int: 20,
        font_size: '20px',
        font_weight: '400',
        icon_margin: '10px',
        icon_font_size: '16px',
    },
    sm: {
        height: '40px',
        height_int: 40,
        horizontal_padding: '10px',
        horizontal_padding_int: 10,
        font_size: '18px',
        font_weight: '400',
        icon_margin: '10px',
        icon_font_size: '16px',
    },
    xs: {
        height: '30px',
        height_int: 24,
        horizontal_padding: '10px',
        horizontal_padding_int: 6,
        font_size: '16px',
        font_weight: '400',
        icon_margin: '6px',
        icon_font_size: '16px',
    },
};

const BUTTON_SIZE_STYLES = Object.keys(BUTTON_SIZE_DATA).reduce( (acc, val) => {
    let data = BUTTON_SIZE_DATA[val];
    acc[val] = css`
        height: ${data.height};
        padding: 0 ${data.horizontal_padding};
        font-size: ${data.font_size};
        font-weight: ${data.font_weight};

        >svg {
            font-size: ${data.icon_font_size};
        }

        >svg.icon-left {
            margin-right: ${data.icon_margin};
        }

        >svg.icon-right {
            margin-left: ${data.icon_margin};
        }
    `;
    return acc;
}, {} );

const getButtonSizeStyles = (size) => {
    if ( BUTTON_SIZE_STYLES?.[size] ) {
        return BUTTON_SIZE_STYLES[size];
    }

    return BUTTON_SIZE_STYLES['default'];
}

export const StyledButton = styled.button`
    color: ${getColorFromSlug('david-yellow')} !important;
    background: ${getColorFromSlug('david-black')};
    border-radius: 5px;
    font-family: 'Oswald', serif;
    border: 2px solid ${getColorFromSlug('david-black')};
    cursor: pointer;
    width: 100%;
    min-width: 100px;
    max-width: 200px;
    justify-content: center;
    display: flex;
    align-items: center;

    &:hover, &:focus {
        background: ${getColorFromSlug('dark-gray')};
        border: 2px solid ${getColorFromSlug('david-black')};
    }

    /* =========================================================================
        SIZE
    ========================================================================= */
    ${({ $size }) => getButtonSizeStyles($size)}
`;

export const YellowButtonWrapper = styled.div`

    ${StyledButton} {
        background: ${getColorFromSlug('david-yellow')};
        color: ${getColorFromSlug('david-black')} !important;

        &:hover, &:focus {
            background: ${getColorFromSlug('dark-yellow')};
    }
`;

export const RedButtonWrapper = styled.div`

    ${StyledButton} {
        color: #fff !important;
        background: ${getColorFromSlug('map-red')};
        border: 2px solid ${getColorFromSlug('david-black')};

        &:hover, &:focus {
            background: ${getColorFromSlug('dark-red')};
    }
`;

// export const CancelButton = styled(StyledButton)`
//     color: ${getColorFromSlug('david-yellow')} !important;
//     background: ${getColorFromSlug('david-black')};
//     border: 2px solid ${getColorFromSlug('david-black')};

//     /* =========================================================================
//         SIZE
//     ========================================================================= */
//     ${({ $size }) => getButtonSizeStyles($size)}

//     &:hover, &:focus {
//         background: #fff;
//     }
// `;

// export const ApproveButton = styled(StyledButton)`
//     color: ${getColorFromSlug('david-black')} !important;
//     background: ${getColorFromSlug('david-yellow')};
//     border: 2px solid ${getColorFromSlug('david-black')};

//     /* =========================================================================
//         SIZE
//     ========================================================================= */
//     ${({ $size }) => getButtonSizeStyles($size)}

//     &:hover, &:focus {
//         background: ${getColorFromSlug('dark-yellow')};;
//     }
// `;

// export const RemoveButton = styled(StyledButton)`
//     color: #fff !important;
//     background: ${getColorFromSlug('map-red')};
//     border: 2px solid ${getColorFromSlug('david-black')};

//     ${({ $size }) => getButtonSizeStyles($size)}

//     &:hover, &:focus {
//         background: #fff;
//     }
// `;

export const SubmitButton = styled(StyledButton)`
    color: ${getColorFromSlug('david-yellow')};
    background: ${getColorFromSlug('david-black')};
    border: 2px solid ${getColorFromSlug('david-black')};

    ${({ $size }) => getButtonSizeStyles($size)}

    &:hover, &:focus {
        background: ${getColorFromSlug('dark-gray')};
    }
`;

// export const LinkButton = styled(StyledButton)`
//     color: ${getColorFromSlug('david-black')} !important;
//     background: ${getColorFromSlug('david-yellow')};
//     border: 2px solid ${getColorFromSlug('david-black')};
//     text-align: center;

//     ${({ $size }) => getButtonSizeStyles($size)}

//     &:hover, &:focus {
//         border: 2px solid ${getColorFromSlug('david-yellow')};
//         cursor: pointer;
//     }
// `;
