import styled from 'styled-components';
import { getColorFromSlug } from 'utility/colors';

export const StyledLink = styled.a`
    color: ${getColorFromSlug('david-yellow')} !important;
    background: ${getColorFromSlug('david-black')};
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    font-family: 'Oswald', serif;
    width: 100%;
    max-width: 150px;
    border: 2px solid ${getColorFromSlug('david-black')};
    text-align: center;

    &:hover, &:focus {
        background: ${getColorFromSlug('dark-gray')};
    }
`;
