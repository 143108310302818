// Samsung Galaxy Tab Active Pro 10.1" resolution is 1920x1200
// https://www.samsung.com/us/business/mobile/tablets/galaxy-tab-active/galaxy-tab-active-pro-10-1-64gb-wi-fi-sm-t540nzkaxar/
export const breakpoints = {
    xxs: 320,
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1260,
    xl: 1440,
    hd: 1921,
    '4k': 3840,
    tabletPortrait: 1200,
    tabletLandscape: 1920,
};

const breakpoint_labels = Object.keys(breakpoints);

var screenWidth = {
    above: {},
    below: {},
    is: {},
};

for (let i = 0; i < breakpoint_labels.length; i++) {
    const label = breakpoint_labels[i];
    const width = breakpoints[label];

    screenWidth.above[label] = `only screen and (min-width:${width}px)`;
    screenWidth.below[label] = `only screen and (max-width:${width - 1}px)`;

    const next_breakpoint_index = i+1;
    if ( breakpoint_labels.hasOwnProperty(next_breakpoint_index) && breakpoints.hasOwnProperty(breakpoint_labels[next_breakpoint_index]) ) {
        const next_breakpoint_label = breakpoint_labels[next_breakpoint_index];
        const next_breakpoint_width = breakpoints[next_breakpoint_label];
        screenWidth.is[label] = `only screen and (min-width:${width}px) and (max-width:${next_breakpoint_width - 1}px)`;
    }
}

export { screenWidth };
