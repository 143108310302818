import {Route, Routes} from "react-router-dom"
import {Helmet, HelmetProvider} from 'react-helmet-async';
import GlobalStyle from "styles/global";
import Header from 'components/Layout/Header/Header';
import Home from "pages/Home";
import Clients from "pages/Clients";
import MapView from "components/Layout/MapView/MapView";
// import AddCut from "pages/AddCut";
import Review from "pages/Review";
import Project from "pages/Project";
import Test from "pages/Test";
import Test2 from "pages/Test2";
import Test3 from "pages/Test3";
import {Anonymous} from "../../pages/Anonymous";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const Layout = () => {
    return (
        <HelmetProvider>
            <Helmet
                htmlAttributes={{
                    lang: 'en'
                }}
                meta={[
                    {
                        name: `viewport`,
                        content: `width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                ]}
                link={[
                    {
                        rel: `preconnect`,
                        href: `https://fonts.gstatic.com`,
                    },
                    {
                        rel: `stylesheet`,
                        href: `https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&family=Oswald:wght@400;500;700&family=Source+Sans+Pro:wght@200;400;700&display=swap`,
                    },
                ]}
            />

            <GlobalStyle />

            <UnauthenticatedTemplate>
                <Anonymous />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <Header />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="review" element={<Review />} />
                    <Route path="map" element={<MapView />} />
                    {/* <Route path="addcut" element={<AddCut />} /> */}
                    <Route path="project/create" element={<Project />} />
                    <Route path="projects/:id/edit" element={<Project />} />
                    <Route path="test" element={<Test />} />
                    <Route path="test2" element={<Test2 />} />
                    <Route path="test3" element={<Test3 />} />
                </Routes>
            </AuthenticatedTemplate>
        </HelmetProvider>
    );
}

export default Layout;
