import React from 'react';
import { StyledLink } from './Link.styles';

const ExternalLink = ({
    to,
    children,
    onClick,
    target,
}) => {
    return (
            <StyledLink href={to} target={target} onClick={onClick}>
                {children}
            </StyledLink>
    );
}

export default ExternalLink;
