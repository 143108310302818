import { forwardRef } from "react";
import AbstractInputField from "./AbstractInputField";

const TextField = forwardRef( ({
    ...otherProps
}, ref) => {
    return (
        <AbstractInputField type="text" ref={ref} {...otherProps} />
    );
} );

export default TextField;
