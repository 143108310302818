import Fuse from 'fuse.js';

export const default_fuse_options = {
    // isCaseSensitive: false,
    includeScore: true,
    shouldSort: false,
    includeMatches: true,
    findAllMatches: true,
    minMatchCharLength: 1,
    // location: 0,
    threshold: 0.3, // a score closer to zero is better
    distance: 9999,
    useExtendedSearch: true,
}

export const highlights = {
    parent_class: 'highlighted-for-fuzzy-search',
    pre: '<span class="fuzzy-search-highlight">',
    post: '</span>',
}

export function createIndex(data, properties, fuse_options = {}) {
    return new Fuse(
        data,
        {...default_fuse_options, ...fuse_options},
        Fuse.createIndex(properties, data)
    );
}

export function searchIndex(index, query) {
    // console.log(index.search(query));
    return index.search(query);
}