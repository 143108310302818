import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {regular, solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import {CloseMobileMenu, MenuItemsWrapper, MenuTitle, MobileMenuBottom, MobileMenuTop, StyledNav} from './Nav.styles';
import NavMenuItem from './NavMenuItem';
import {loginRequest} from 'authConfig';
import {clearStorage} from 'utility/Authentication/storageUtils';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';

const Nav = ({
    open = false,
    mobileMenuCloseHandler = () => {},
}) => {
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance.loginRedirect({
            ...loginRequest
        }).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        clearStorage(activeAccount);

        instance.logoutRedirect().catch((error) => console.log(error));
    };

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <StyledNav open={open}>
            <MobileMenuTop>
                <MenuTitle>Menu</MenuTitle>
                <CloseMobileMenu onClick={mobileMenuCloseHandler}>
                    <FontAwesomeIcon icon={regular('times')} />
                </CloseMobileMenu>
            </MobileMenuTop>
            <MobileMenuBottom>
                <MenuItemsWrapper>
                    <NavMenuItem to="/" text="Home" icon={solid('home')} mobileOnly />
                    <NavMenuItem to="/project/create" text="Add Project" icon={solid('plus')} iconMobileOnly />
                    <NavMenuItem to="/clients" text="Client List" icon={solid('users')} iconMobileOnly />
                    <NavMenuItem to="/review" text="Review Cuts" icon={solid('check')} iconMobileOnly />
                    <AuthenticatedTemplate>
                        <NavMenuItem text={activeAccount.name} icon={solid('user')} iconMobileOnly>
                            <NavMenuItem text="Logout" onClick={handleLogoutRedirect} icon={solid('right-from-bracket')} isSubmenuItem iconMobileOnly/>
                        </NavMenuItem>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                            <NavMenuItem text="Login" onClick={handleLoginRedirect} icon={solid('lock')} iconMobileOnly />
                    </UnauthenticatedTemplate>
                </MenuItemsWrapper>
            </MobileMenuBottom>
        </StyledNav>
    );
}

export default Nav;
