import React from "react";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "./authConfig";
import Button from "components/Button/Button";
import { YellowButtonWrapper } from "./components/Button/Button.styles";


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    return (
        <YellowButtonWrapper style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Button onClick={handleLogin} style={{marginTop:'30px'}}>Login</Button>
        </YellowButtonWrapper>
    );
}
