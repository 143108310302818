import styled, { css } from 'styled-components';

import { screenWidth } from 'utility/screen-width';
import { getColorFromSlug } from 'utility/colors';

const HEADER_BG_COLOR = getColorFromSlug('david-yellow');
const SUBMENU_BG_COLOR = getColorFromSlug('david-yellow');
const SUBMENU_ITEM_HOVER_BG_COLOR = getColorFromSlug('dark-yellow');;

export const StyledMenuItem = styled.div`
    display:flex;
    align-items:center;
    font-family:'Montserrat',sans-serif;

    >div:not(.submenu) {
        cursor:default;
    }

    >a,
    >div:not(.submenu),
    >svg,
    >.fa-layers {
        color:${getColorFromSlug('david-black')};
        text-decoration:none;
    }

    >a,
    >div:not(.submenu) {
        display:flex;
        align-items:center;
        width:100%;
        height:100%;
        padding: 0 15px;
        white-space: nowrap;
        font-weight: 700;
        text-transform:uppercase;
    }

    ${({ textMobileOnly }) => textMobileOnly && `
        @media ${screenWidth.above.sm} {
            >a,
            >div:not(.submenu) {
                >span {
                    display:none;
                }
            }
        }
    `}

    ${({ iconMobileOnly }) => iconMobileOnly && `
        @media ${screenWidth.above.sm} {
            >a,
            >div:not(.submenu) {
                >svg,
                >.fa-layers {
                    display:none;
                }
            }
        }
    `}

    @media ${screenWidth.above.sm} {
        height:100%;
    }

    @media ${screenWidth.below.sm} {
        height:50px;

        >a,
        >div:not(.submenu) {
            padding: 0 20px;

            >svg,
            >.fa-layers {
                width: 1.25em !important;
                margin-right: 15px;
            }
        }
    }

    ${({ mobileOnly }) => mobileOnly && `
        @media ${screenWidth.above.sm} {
            display: none !important;
        }
    `}
`;

export const menu_item_before_pseudo_css = css`
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.4);
`;

export const StyledTopLevelMenuItem = styled(StyledMenuItem)`
    @media ${screenWidth.above.sm} {
        >a,
        >div:not(.submenu) {
            ${({ hovered }) => hovered && `
                z-index: 2;
            `}

            &:before {
                ${menu_item_before_pseudo_css}
                opacity: ${({ hovered }) => hovered ? 1 : 0};
                transition: opacity 0.15s;
            }
        }
    }

    @media ${screenWidth.below.sm} {
        >a,
        >div:not(.submenu) {
            &:before {
                content:'';
                display:block;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background: rgba(255,255,255,0.4);
                opacity: ${({ hovered }) => hovered ? 1 : 0};
                transition: opacity 0.15s;
            }

            &:after {
                content:'';
                display:block;
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                height:1px;
                background: ${SUBMENU_ITEM_HOVER_BG_COLOR};
            }
        }
    }
`;

export const StyledSubmenu = styled.div`
    @media ${screenWidth.above.sm} {
        display:block;
        position:absolute;
        top:100%;
        left:0;
        min-width:100%;
        background: ${HEADER_BG_COLOR};
        box-shadow: 0 0 11px rgba(0,0,0,0.6);
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: visibility 0.15s, opacity 0.15s, transform 0.15s;

        ${({ open }) => open && `
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        `}
    }

    @media ${screenWidth.below.sm} {
        display:block;
        position:absolute;
        top:100%;
        left:0;
        min-width:100%;
        background: ${HEADER_BG_COLOR};
        box-shadow: 0 0 5px rgba(0,0,0,0.6);
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: visibility 0.15s, opacity 0.15s, transform 0.15s;

        ${({ open }) => open && `
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        `}
    }
`;

export const StyledSubmenuItem = styled(StyledMenuItem)`
    @media ${screenWidth.above.sm} {
        >a,
        >div:not(.submenu) {
            background: ${({ hovered }) => hovered ? SUBMENU_ITEM_HOVER_BG_COLOR : SUBMENU_BG_COLOR};
            height:45px;
        }
    }
`;