import styled, {css} from 'styled-components';
import {getColorFromSlug} from 'utility/colors';

const RADIO_BUTTON_CSS = css`
    -webkit-appearance: none;
    appearance: none;
    background-color: ${getColorFromSlug('david-yellow')};
    margin: 0;
    font: inherit;
    color: ${getColorFromSlug('dark-yellow')};
    width: 1.15em;
    height: 1.15em;
    border: 1px solid ${getColorFromSlug('david-black')};
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-top: 2px;
    cursor: pointer;

    &:before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 30ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: ${getColorFromSlug('david-black')};
    }

    &:checked::before {
    transform: scale(1);
    }

    &:focus {
    outline: max(1px, 0.1em) solid currentColor;
    outline-offset: max(1px, 0.1em);
    }
`;

export const StyledRadioButton = styled.input.attrs({ type: 'radio' })`
    ${RADIO_BUTTON_CSS}
`;

export const StyledRadioButtonLabel = styled.label`
    font-family:'Oswald', serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    display: grid;
    grid-template-columns: 1.2em auto;
    gap: 0.35em;
    margin-top: .5em;
    margin-bottom: .5em;
    cursor: pointer;
`;
