import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';

import InputWithAddon from "./InputWithAddon";
import {StyledClearInput} from "./ClearInput.styles";
import TextField from "./TextField";

const ClearInput = forwardRef( ({
    onChangeHandler = null,
    onClear = null,
    placeholder,
    noMargin,
    isFormField = true,
    ...otherProps
}, ref) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(ref);

    // this is required in order to both forward the ref and use the ref inside this component at the same time
    // reference: https://beta.reactjs.org/reference/react/useImperativeHandle
    // reference: https://stackoverflow.com/a/68163315
    useImperativeHandle(ref, () => inputRef.current);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    }

    const clear = () => {
        setInputValue('');

        if (onClear && typeof onClear === 'function') {
            onClear();
        }

        inputRef?.current.focus();
    }

    useEffect(() => {
        if (onChangeHandler && typeof onChangeHandler === 'function') {
            onChangeHandler(inputValue);
        }
    }, [inputValue])

    const keyDownHandler = (e) => {
        switch(true) {
            case(e.key === 'Esc' || e.key === 'Escape'):
                clear();
                e.preventDefault();
                return false;
            break;
            default:
            break;
        }
    }

    return (
        <StyledClearInput>
            <InputWithAddon
                iconRight={!!inputValue && icon({name: 'times', style: 'regular'})}
                iconRightClickHandler={clear}
                {...otherProps}
            >
                <TextField
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={keyDownHandler}
                    noMargin={noMargin}
                    placeholder={placeholder}
                    isFormField={isFormField}
                />
            </InputWithAddon>
        </StyledClearInput>
    );
} );

export default ClearInput;
