import styled from 'styled-components';
import {getColorFromSlug} from 'utility/colors';
import {screenWidth} from 'utility/screen-width';

export const StyledSidebar = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    z-index: 1100;
    margin: 10px;
    background: #fff;
    height: calc(100vh - 90px);
    max-width: 100%;
`;

export const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    
`;

export const SidebarHeaderWrapper = styled.div`
    position: fixed;
    background-color: #fff;
    z-index: 200;
    padding-top: 10px;
    height: 60px;
    top: 80px;

    border-bottom: 1px dotted black;

    @media ${screenWidth.above.hd} {
        width: 18%;
    }

    @media ${screenWidth.below.hd} {
        width: 21.5%;
    }

    @media ${screenWidth.below.xl} {
        width: 26%;
    }

    @media ${screenWidth.below.lg} {
        width: 35%;
    }

    @media ${screenWidth.below.md}{
        width: 45%;
    }

    @media ${screenWidth.below.sm}{
        width: 67%;
    }

    @media ${screenWidth.below.xs}{
        width: 88%;
    }
`;

export const SidebarOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    background-color: #333;
    opacity: .3;

    @media ${screenWidth.above.hd} {
        width: 20%;
    }

    @media ${screenWidth.below.hd} {
        width: 25%;
    }

    @media ${screenWidth.below.xl} {
        width: 30%;
    }

    @media ${screenWidth.below.lg} {
        width: 40%;
    }

    @media ${screenWidth.below.md}{
        width: 50%;
    }

    @media ${screenWidth.below.sm}{
        width: 75%;
    }

    @media ${screenWidth.below.xs}{
        width: 100%;
    }
`;

export const SidebarWrapper = styled.div`
    position: fixed;
    z-index: 1050;
    overflow-y: auto;
    overflow-x: hidden;
    outline: 0;

    @media ${screenWidth.above.hd} {
        width: 20%;
    }

    @media ${screenWidth.below.hd} {
        width: 25%;
    }

    @media ${screenWidth.below.xl} {
        width: 30%;
    }

    @media ${screenWidth.below.lg} {
        width: 40%;
    }

    @media ${screenWidth.below.md}{
        width: 50%;
    }

    @media ${screenWidth.below.sm}{
        width: 75%;
    }

    @media ${screenWidth.below.xs}{
        width: 100%;
    }
`;

export const SidebarBody = styled.div`
    width: 100%;
`;

export const SidebarCloseButton = styled.button` 
    font-size: 28px;
    font-weight: 400;
    color: gray;
    cursor: pointer;
    border: none;
    background: transparent;
`;

export const CutInfoBox = styled.div`
    border: 1px solid ${getColorFromSlug('david-black')};
    padding: 0px;
    margin-top: 20px;
`;

export const CutInfoHeader = styled.div`
    font-size:24px;
    width: 100%;
    font-family: 'Oswald', serif;
    margin-bottom: 10px;
    line-height: 1.1;
    font-weight: 400;
    min-height: 50px;
    max-height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 10px;
    border-bottom: 1px solid ${getColorFromSlug('david-black')};
`;

export const CutInfo = styled.div`
    margin: 15px 0;
    padding-left: 10px;
    font-size: 16px;
`

export const IconHover = styled.div`
    cursor: pointer; 
`
