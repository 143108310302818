import styled from 'styled-components';
import {getColorFromSlug} from 'utility/colors';
import {StyledFormField} from './FormField.styles';


export const StyledForm = styled.form`
    display: block;
    width: 100%;
    padding: 15px 0 0 0;

    ${StyledFormField} {
        margin-bottom: 15px;
    }
`;

export const StyledCutLegend = styled.legend`
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid black;
    margin-bottom: 10px;

    ${StyledFormField} {
        margin-bottom: 15px;
    }
`;

export const StyledSuccessMessageWrapper = styled.div`
    margin-bottom: 15px;
`;

export const EditCutInfo = styled.div`
    margin: 15px 0 25px 0;
    padding-left: 5px;
    font-size: 20px;
`
////////////////////////////////////////////////
// OLD STYLES
/////////////////////////////////////////////////
export const INPUT_HEIGHT = 55;

export const StyledLabel = styled.label`
    padding-bottom: 25px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    width: 100%;
    font-family:'Oswald', serif;
`;

export const StyledInput = styled.input`
    padding: 15px;
    width: 100%;
    height: ${INPUT_HEIGHT}px;
    font-size: 18px;
    margin: 0;
    background-color: #fff;
    border: 1px solid black;
`;

export const StyledSelect = styled.select`
    padding: 15px;
    width: 100%;
    height: ${INPUT_HEIGHT}px;
    font-size: 18px;
    margin: 0;
    background-color: white;
    border: 1px solid black;
`;

export const StyledTextarea = styled.textarea`
    padding: 15px;
    width: 100%;
    font-size: 18px;
    margin: 0;
`;
