import NumberField from "./Form/FormComponents/NumberField";
import TextField from "./Form/FormComponents/TextField";
import SelectField from "./Form/FormComponents/SelectField";
import Column from "./Grid/Column";
import Row from "./Grid/Row";
import FuzzySearchSelect from "./FuzzySearch/FuzzySearchSelect";

const Testcomponent = () => {
    return (
        <Row gutter={30}>
            <Column width={1 / 2}>
                <TextField
                    label="Text Field"
                    name="textfield"
                />
                <NumberField
                    label="Number Field"
                    name="numberfield"
                />
                <SelectField
                    label="Select Field"
                    name="selectfield"
                    placeholder="choose your fate"
                    choices={[
                        {
                            label: 'hello1',
                            value: { id: 1, uuid: 'asdfasdf' }
                        },
                        {
                            label: 'hello2',
                            value: 2
                        },
                        {
                            label: 'hello3',
                        },
                        'hello4'
                    ]}
                />

                <FuzzySearchSelect
                    label="Client"
                    name="client"
                    placeholder="Select One..."
                    items={[
                        {
                            label: 'Client 1',
                            value: 1,
                            id: 1,
                        },
                        {
                            label: 'Client 2',
                            value: 2,
                            id: 2,
                        },
                        {
                            label: 'Client 3',
                            value: 3,
                            id: 3,
                        },
                        {
                            label: 'Client 4',
                            value: 4,
                            id: 4,
                        },
                    ]}
                    addText="Add Client"
                />
            </Column>
        </Row>
    );
}

export default Testcomponent;