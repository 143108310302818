export var bodyScrollDisabled = false;
var bodyScrollTop;

export function disableBodyScroll() {
    const overflow_y = (document.body.scrollHeight > window.innerHeight ? 'scroll' : 'hidden');

    bodyScrollTop  = window.pageYOffset || document.documentElement.scrollTop;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.weight = '100%';
    document.body.style.top = `-${bodyScrollTop}px`;
    document.body.style.overflowY = overflow_y;
    bodyScrollDisabled = true;
}

export function enableBodyScroll() {
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.height = '';
    document.body.style.top = '';
    document.body.style.overflowY = '';
    document.documentElement.scrollTop = bodyScrollTop;
    bodyScrollDisabled = false;
}
