import styled from 'styled-components';

const FlexWraper = ({
    children
}) => {
    

    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default FlexWraper;

const Wrapper = styled.div`    
    display: flex;
    width: 100%;
    max-width: 100%;
`;
