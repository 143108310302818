import { Overlay, LoadingWrapper } from "./LoadingOverlay.styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

function LoadingOverlay({
    isLighter,
    children
}) {

    return(
        <Overlay>
            <LoadingWrapper $isLighter={isLighter}>
                <FontAwesomeIcon icon={regular('spinner-third')} style={{marginBottom: '8px'}} className="fa-spin fa-4x" />
                {children}
            </LoadingWrapper>
        </Overlay>
    );
}

export default LoadingOverlay;