import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { getColor } from "utility/colors";

const FormFieldError = ({
    children,
    ...otherProps
}) => {
    return (
        <StyledFormFieldError>
            <StyledIconWrapper>
                <FontAwesomeIcon icon={faExclamationTriangle} />
            </StyledIconWrapper>
            <StyledMessageWrapper>
                {children}
            </StyledMessageWrapper>
        </StyledFormFieldError>
    );
}

export default FormFieldError;

export const StyledFormFieldError = styled.div`
    display: flex;
    color: ${getColor('error-text')};
    border: 1px solid ${getColor('error-border')};
    background: ${getColor('error-bg')};
    font-style: italic;
    padding: 5px;
`;

export const StyledIconWrapper = styled.div`
    flex-shrink: 0;
    font-size: 16px;
    margin-right: 5px;
`;

export const StyledMessageWrapper = styled.div`
    flex-grow: 1;
`;
